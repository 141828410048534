export const recentActivityData = [
  {
    id: 1,
    icon1: "sign-btc",
    icon2: "arrow-down-left bg-success-dim icon-circle",
    desc: "Buy Bitcoin",
    date: "Nov 12, 2019",
    time: "11:37 PM",
    ref: "RE2309232",
    usd: "4,565.75",
    amount: "0.2040",
  },
  {
    id: 2,
    icon1: "sign-eth",
    icon2: "arrow-down-left bg-success-dim icon-circle",
    desc: "Buy Ethereum",
    date: "Sep 02, 2019",
    time: "10:37 PM",
    ref: "RE2309233",
    usd: "2,039.39",
    amount: "0.12600",
  },
  {
    id: 3,
    icon1: "sign-btc",
    icon2: "arrow-up-right bg-purple-dim icon-circle",
    icon1Class: "bg-btc-dim",
    icon2Class: "bg-purple-dim",
    desc: "Sell Bitcoin",
    date: "May 25, 2019",
    time: "10:45 PM",
    ref: "RE2309234",
    usd: "9,285.71 ",
    amount: "0.94750",
  },
  {
    id: 4,
    icon1: "sign-eth",
    icon2: "arrow-up-right bg-purple-dim icon-circle",
    desc: "Sell Etherum",
    date: "Jun 01, 2019",
    time: "10:25 PM",
    ref: "RE2309235",
    usd: "12,596.75",
    amount: "1.02050",
  },
];
