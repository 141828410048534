import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
    Input,
    Form,
    Spinner,
    Label,
    Row,
    Col,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    Button,
    FormGroup,
    FormFeedback
} from "reactstrap";
import { Block, BlockHead, BlockTitle, Icon } from "../../../components/Component";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useApiService from "../../../hooks/apiService";
import CustomToast from "../../../components/toast";
import { UseMemberWalletBalanceUpdate } from "../../../hooks/account-balance";
import { UseAccountLockStatus } from "../../../hooks/lock-account-status";

const SellTeamShare = () => {
    const { apiService, toastMessage, setToastMessage } = useApiService();
    const { updateMemberWalletBalance } = UseMemberWalletBalanceUpdate()
    const { accountLockStatus } = UseAccountLockStatus()

    const { userInfo, walletInfo, accountLockstatus } = useSelector((state) => state.menu);
    const location = useLocation();

    console.log("locationsss", location);
    const [teamDetails] = useState(location?.state)
    const [teamShares, setTeamShares] = useState(teamDetails?.total_shares ? teamDetails?.total_shares : "");
    const [runs, setRuns] = useState(teamDetails?.total_shares * teamDetails?.price_limit || teamDetails?.market_price || 0);
    const [error, setError] = useState("");
    const [isAddSharesLoading, setIsAddSharesLoading] = useState(false)
    const [modal, setModal] = useState({
        confirm: false,
        sellCoin: false,
    });
    const [sellOption, setSellOption] = useState("market"); // 'market' or 'limit'
    const [limitPrice, setLimitPrice] = useState(teamDetails?.price_limit ? teamDetails?.price_limit : "");
    const [runsPerShare] = useState(Number(teamDetails?.price_limit || teamDetails?.market_price || 0)?.toFixed(2))
    const [limitPriceError, setLimitPriceError] = useState("");

    const handleClose = () => {
        setToastMessage(null);
    };


    // Handler for the runs input field
    const handleSharesChange = (e) => {
        const value = e.target.value;
        setTeamShares(value);

        // Clear any previous error
        setError("");
        console.log("value > teamDetails?.total_shares",value > Number(teamDetails?.total_shares), value, teamDetails?.total_shares)
        // Check if the entered value is greater than the total shares
        if (value > Number(teamDetails?.total_shares)) {
            setError(`You cannot sell more than ${teamDetails?.total_shares} shares.`);
            setRuns("");
        } else {
            // Update the runs based on valid input
            if (value) {
                setRuns(value * runsPerShare);
            } else {
                setRuns("");
            }
        }
    };


    // Validation function to ensure runs input is not empty
    const validateForm = () => {
        if (teamShares === "") {
            setError("Please enter the number of shares you want to sell.");
            return false;
        }
        console.log("value > teamDetails?.total_shares",teamShares > Number(teamDetails?.total_shares), teamShares, teamDetails?.total_shares)

        if (teamShares > Number(teamDetails?.total_shares)) {
            setError(`You cannot sell more than ${teamDetails?.total_shares} shares.`);
            return false;
        }
        if (sellOption === "limit" && !limitPrice) {
            setLimitPriceError("Limit Price is required.");
            return false;
        }
        return true;
    };


    // function to close the form modal
    const onFormCancel = () => {
        setModal({ confirm: false, sellCoin: false });
    };
    const handleSellOptionChange = (e) => {
        setSellOption(e.target.value);

    };

    const onPriceLimitChange = (e) => {
        setLimitPrice(e.target.value)
        if (teamShares) {
            setRuns(teamShares * e.target.value);
        }
        setLimitPriceError('')
    }


    const handleContinueToSell = async (e) => {
        e.preventDefault();

        // await accountLockStatus();

        // if (!accountLockstatus) {
        console.log("teamDetails?.order_status",teamDetails,teamDetails?.ipo_status)
            if (teamDetails?.order_status === 'pending') {
                if (validateForm()) {
                    setModal({ sellCoin: true })
                }
            } else {
                if (teamDetails?.ipo_status === 'pending') {
                    setToastMessage({
                        message: "You are not able to sell this team shares untill entire shares are sold out!",
                        toastType: "error"
                    });
                } else {
                    console.log("vaidateee",validateForm())
                    if (validateForm()) {
                        setModal({ sellCoin: true })
                    }
                }
            }
        // } else {
        //     setToastMessage({
        //         message: "Your account has been locked, please try again after 24 hours..",
        //         toastType: "error"
        //     })
        // }

    }

    const handleConfirmOrder = async (e) => {
        const requestPayload = {
            team_shares: teamShares,
            runs: runs,
            limitPrice: limitPrice,
            team_id: teamDetails?.team_id,
            member_id: userInfo?.id,
            account_number: userInfo?.account_number,
            team_account_number: teamDetails?.account_number
        };

        if (teamDetails?.order_status === "pending") {
            requestPayload.member_share_id = teamDetails?.id,
            requestPayload.order_type = teamDetails?.order_type
        }

        console.log("requestPayload", requestPayload);

        try {
            setIsAddSharesLoading(true);
            const url = teamDetails?.order_status === "pending" ? 
            teamDetails?.order_type === 'sell' ? '/member-modify-team-sell-shares' : '/member-modify-team-buy-shares'  : '/member-pending-team-shares';
            // const url = '/member-pending-team-shares';

            const { result, success, message } = await apiService('POST', url, requestPayload);

            console.log("validate pin response", result, success);

            if (success) {
                await updateMemberWalletBalance();
                setModal({
                    sellCoin: false,
                    confirm: true
                })
                setToastMessage({
                    // message: sellOption === "limit" ? "Team Shares Sell Request Send Successfully!" : "Team Shares Sell Successfully!",
                    message: "Team Shares Sell Request Send Successfully!",
                    toastType: "success"
                });

            } else if (message === "Failed to authenticate") {
                setToastMessage({
                    message: "Failed to authenticate. Please login again to continue...",
                    toastType: "error"
                });
                navigate('/');
            } else {
                setToastMessage({
                    message: message,
                    toastType: "error"
                });
            }
        } catch (err) {
            setToastMessage({
                message: err || 'An error occurred',
                toastType: "error"
            });
        } finally {
            setIsAddSharesLoading(false);
        }

    }

    return (
        <React.Fragment>
            <Head title="Crypto Dashboard"></Head>
            <Content>
                <div className="buysell wide-xs m-auto">
                    <div className="buysell-nav text-center">
                        <ul className="nk-nav nav nav-tabs nav-tabs-s2">
                            <li className="nav-item">
                                <a className="nav-link active" href="#">
                                    Sell Shares
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="buysell-title text-center mb-5 mt-5">
                        <h2 className="title">Are you want to Sell {teamDetails?.team_name} Share!</h2>
                    </div>
                    <div className="buysell-block">
                        <Form className="buysell-form">

                            <div className="form-group buysell-field">
                                <div className="form-label-group">
                                    <Label className="form-label" for="buysell-amount">
                                        Enter less than or equals to {teamDetails?.total_shares} Shares that you want to sell
                                    </Label>
                                </div>
                                <div className="form-control-group">
                                    <Input
                                        type="number"
                                        id="runsInput"
                                        value={teamShares}
                                        className="form-control form-control-lg form-control-number"
                                        onChange={handleSharesChange}
                                        invalid={!!error}
                                        placeholder="Enter number of Shares"
                                    />
                                    {error && <FormFeedback>{error}</FormFeedback>}
                                </div>
                            </div>

                            {/* <div className="form-group buysell-field">
                                <div className="form-label-group">
                                    <Label className="form-label" for="buysell-amount">
                                        Market Price
                                    </Label>
                                </div>
                                <div className="form-control-group">
                                    <Input
                                        type="text"
                                        className="form-control form-control-lg form-control-number"
                                        id="buysell-amount"
                                        name="bs-amount"
                                        placeholder={`${runsPerShare}`}
                                        value={runsPerShare}
                                        readOnly
                                    />
                                </div>
                            </div> */}

                            <div className="form-group buysell-field">
                                <div className="form-label-group">
                                    <Label className="form-label" for="buysell-amount">
                                        Enter Ask Price
                                    </Label>
                                </div>
                                <div className="form-control-group">
                                    <Input
                                        type="number"
                                        id="runsInput"
                                        className="form-control form-control-lg form-control-number"
                                        value={limitPrice}
                                        onChange={onPriceLimitChange}
                                        placeholder="Enter Ask Price"
                                        invalid={!!limitPriceError} // Show validation error if exists
                                    />
                                    {limitPriceError && <FormFeedback>{limitPriceError}</FormFeedback>}
                                </div>
                            </div>

                            <div className="form-group buysell-field">
                                <div className="form-label-group">
                                    <Label className="form-label" for="buysell-amount">
                                        Runs
                                    </Label>
                                </div>
                                <div className="form-control-group">
                                    <Input
                                        type="text"
                                        className="form-control form-control-lg form-control-number"
                                        id="buysell-amount"
                                        name="bs-amount"
                                        placeholder={`1 Share = ${runsPerShare} Runs`}
                                        value={runs}
                                        readOnly
                                    />
                                </div>
                                {/* <div className="form-note-group">
                                    <span className="buysell-min form-note-alt"></span>
                                    <span className="buysell-rate form-note-alt">1 share = {runsPerShare} Runs</span>
                                </div> */}
                            </div>

                            {/* <FormGroup tag="fieldset">
                                <legend>Sell Option</legend>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="sellOption"
                                                    value="market"
                                                    checked={sellOption === "market"}
                                                    onChange={handleSellOptionChange}
                                                />{" "}
                                                Sell at Market Price
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="sellOption"
                                                    value="limit"
                                                    checked={sellOption === "limit"}
                                                    onChange={handleSellOptionChange}
                                                />{" "}
                                                Sell at Limit Price
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>


                            </FormGroup>

                            {sellOption === "limit" ? (
                            <div className="form-group buysell-field">
                                <div className="form-label-group">
                                    <Label className="form-label" for="buysell-amount">
                                        Price Limit
                                    </Label>
                                </div>
                                <div className="form-control-group">
                                    <Input
                                        type="number"
                                        id="runsInput"
                                        className="form-control form-control-lg form-control-number"
                                        value={limitPrice}
                                        onChange={onPriceLimitChange}
                                        placeholder="Enter Price Limit"
                                        invalid={!!limitPriceError} // Show validation error if exists
                                    />
                                    {limitPriceError && <FormFeedback>{limitPriceError}</FormFeedback>}
                                </div>
                                <div className="form-note-group">
                                        <span className="buysell-min form-note-alt"></span>
                                        <span className="buysell-rate form-note-alt">Order will be executed at {runsPerShare}</span>
                                    </div>
                            </div>
                            ) : (
                                <div className="form-group buysell-field">
                                    <div className="form-label-group">
                                        <Label className="form-label" for="buysell-amount">
                                            Market Price
                                        </Label>
                                    </div>
                                    <div className="form-control-group">
                                        <Input
                                            type="number"
                                            id="runsInput"
                                            value={runsPerShare}
                                            className="form-control form-control-lg form-control-number"
                                            placeholder="Enter number of Shares"
                                            readOnly
                                        />
                                    </div>
                                    <div className="form-note-group">
                                        <span className="buysell-min form-note-alt"></span>
                                        <span className="buysell-rate form-note-alt">Sell At current market price</span>
                                    </div>
                                </div>
                            )} */}

                            <div className="buysell-field form-action">
                                <Button
                                    color="primary"
                                    className="btn btn-lg btn-block btn-primary"
                                    onClick={handleContinueToSell}
                                >
                                    {teamDetails?.order_status === 'pending' ? 'Modify' : 'Continue to Sell'}
                                </Button>
                            </div>

                        </Form>
                    </div>
                </div>
                <Modal
                    isOpen={modal.sellCoin}
                    toggle={() => setModal({ sellCoin: false })}
                    className="modal-dialog-centered"
                    size="md"
                >
                    <a
                        href="#close"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm" />
                    </a>
                    <ModalBody className="modal-body-lg">
                        <BlockHead className="nk-block-head-xs text-center">
                            <BlockTitle tag="h5">Confirm Shares</BlockTitle>
                            <div className="nk-block-text">
                                <div className="caption-text">
                                    {/* You are about to {sellOption === "limit" ? 'requested' : 'sell'} <strong>{teamShares}</strong> Shares for <strong>{runs}</strong> Runs* */}
                                    You are about to requested<strong>{teamShares}</strong> Shares for <strong>{runs}</strong> Runs*
                                </div>
                                <span className="sub-text-sm">Share rate: 1 Share = {runsPerShare} Runs</span>
                            </div>
                        </BlockHead>
                        <Block>
                            <div className="buysell-field form-action text-center">
                                <div>
                                    <Button
                                        color="primary"
                                        className="btn btn-primary btn-lg"
                                        onClick={handleConfirmOrder}
                                    >
                                        {isAddSharesLoading ? <Spinner size="sm" color="light" /> : "Sell Shares"}
                                    </Button>
                                </div>
                                <div className="pt-3">
                                    <a
                                        href="#"
                                        className="link link-danger"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                    >
                                        Cancel
                                    </a>
                                </div>
                            </div>
                        </Block>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={modal.confirm}
                    toggle={() => setModal({ confirm: false })}
                    className="modal-dialog-centered"
                    size="md"
                >
                    <a
                        href="#close"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm" />
                    </a>
                    <ModalBody className="modal-body-lg text-center">
                        <div className="nk-modal">
                            <Icon name="check" className="nk-modal-icon icon-circle icon-circle-xxl bg-success" />
                            {/* <h4 className="nk-modal-title">{sellOption === "limit" ? 'Your sell shares requested successfully!' : 'Successfully sell share!'}</h4> */}
                            <h4 className="nk-modal-title">Your sell shares requested successfully!</h4>
                            <div className="nk-modal-text">
                                <p className="caption-text">
                                    {/* Successfully {sellOption === "limit" ? 'Requested' : 'Sell'} <strong>{teamShares}</strong> Shares for <strong>{runs} Runs</strong> USD. */}
                                    Successfully Requested<strong>{teamShares}</strong> Shares for <strong>{runs} Runs</strong> USD.
                                </p>
                            </div>
                            <div className="nk-modal-action-lg">
                                <ul className="btn-group gx-4">
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/team-auction-list`} className="btn btn-lg btn-mw btn-primary">
                                            Return
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
            <CustomToast
                open={toastMessage?.toastType ? true : false}
                handleClose={handleClose}
                severity={toastMessage?.toastType}
                message={toastMessage?.message}
            />
        </React.Fragment>
    );
};

export default SellTeamShare;
