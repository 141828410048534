// src/services/useApiService.js
import { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { baseUrl } from '../config/config';

const useApiService = () => {
    const { TOKEN } = useSelector((state)=>state.menu)
    const [isLoading, setIsLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState({
        message: "",
        toastType: ""
    });

    const apiService = async (method, endpoint, data = null, params = null) => {
        setIsLoading(true);
        try {
            const config = {
                method: method.toUpperCase(),
                url: `${baseUrl}${endpoint}`,
                data: data,
                params: params,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + TOKEN
                }
            };

            const response = await axios(config);
            console.log("api response",response)
            if (response.data.success) {
                setToastMessage({
                    message: response.data.message || 'Operation successful!',
                    toastType: "success"
                });
                return { result: response?.data?.result, total: response?.data?.total, success: response?.data?.success, isLoading: false }; // Return result and loading state
            } else if (response?.data?.message === "Failed to authenticate") {
                setToastMessage({
                    message: "Faild to authenticate.Please login again to continue...",
                    toastType: "error"
                })
                return { result: [], total: 0, isLoading: false, message: response?.data?.message }
                // navigate('/')
            }else {
                setToastMessage({
                    message: response.data.message || 'API call failed',
                    toastType: "error"
                });
                return { result: [], total: 0, isLoading: false }
            }
        } catch (error) {
            console.log('API call error:', error);
            setToastMessage({
                message: error.response?.data?.message || 'API call failed',
                toastType: "error"
            });
            return { result: [], total: 0, isLoading: false,  message: error.response?.data?.message }
        } finally {
            setIsLoading(false); // Ensure loading state is reset
        }
    };

    return { apiService, isLoading, toastMessage, setToastMessage };
};

export default useApiService;
