import React, { useLayoutEffect } from "react";
import { Routes,Route, useLocation } from "react-router-dom";
import { ProductContextProvider } from "../pages/pre-built/products/ProductContext";
import { UserContextProvider } from "../pages/pre-built/user-manage/UserContext";

import Sales from "../pages/Sales";
import Crypto from "../pages/Crypto";
import Analytics from "../pages/Analytics";
import Invest from "../pages/Invest";

import CryptoDashboard from "../pages/panel/crypto-panel/Index";
import Orders from "../pages/panel/crypto-panel/Orders";
import Account from "../pages/panel/crypto-panel/Account";
import BuySell from "../pages/panel/crypto-panel/BuySell";
import Wallets from "../pages/panel/crypto-panel/Wallets";
import WalletsBtc from "../pages/panel/crypto-panel/WalletsBtc";
import PanelChat from "../pages/panel/crypto-panel/chat/ChatContainer";
import Welcome from "../pages/panel/crypto-panel/pages/Welcome";
import KycApplications from "../pages/panel/crypto-panel/pages/KycApplications";
import KycForms from "../pages/panel/crypto-panel/pages/KycForms";
import CryptoUserProfileLayout from "../pages/panel/crypto-panel/user/UserProfileLayout";
import CryptoUserProfileRegular from "../pages/panel/crypto-panel/user/UserProfileRegular";
import CryptoUserProfileSetting from "../pages/panel/crypto-panel/user/UserProfileSetting";
import CryptoUserProfileNotification from "../pages/panel/crypto-panel/user/UserProfileNotification";
import CryptoUserProfileConnected from "../pages/panel/crypto-panel/user/UserProfileConnected";
import CryptoUserProfileActivity from "../pages/panel/crypto-panel/user/UserProfileActivity";

import Component from "../pages/components/Index";
import Accordian from "../pages/components/Accordions";
import Alerts from "../pages/components/Alerts";
import Avatar from "../pages/components/Avatar";
import Badges from "../pages/components/Badges";
import Breadcrumbs from "../pages/components/Breadcrumbs";
import ButtonGroup from "../pages/components/ButtonGroup";
import Buttons from "../pages/components/Buttons";
import Cards from "../pages/components/Cards";
import Carousel from "../pages/components/Carousel";
import Dropdowns from "../pages/components/Dropdowns";
import FormElements from "../pages/components/forms/FormElements";
import FormLayouts from "../pages/components/forms/FormLayouts";
import FormValidation from "../pages/components/forms/FormValidation";
import CardWidgets from "../pages/components/widgets/CardWidgets";
import ChartWidgets from "../pages/components/widgets/ChartWidgets";
import RatingWidgets from "../pages/components/widgets/RatingWidgets";
import SlickPage from "../pages/components/misc/Slick";
import DualListPage from "../pages/components/misc/DualListbox";
import JsTreePreview from "../pages/components/misc/JsTree";
import ReactToastify from "../pages/components/misc/ReactToastify";
import SweetAlertPage from "../pages/components/misc/SweetAlert";
import BeautifulDnd from "../pages/components/misc/BeautifulDnd";
import GoogleMapPage from "../pages/components/misc/GoogleMap";
import Modals from "../pages/components/Modals";
import Pagination from "../pages/components/Pagination";
import Popovers from "../pages/components/Popovers";
import Progress from "../pages/components/Progress";
import Spinner from "../pages/components/Spinner";
import Tabs from "../pages/components/Tabs";
import Toast from "../pages/components/Toast";
import Tooltips from "../pages/components/Tooltips";
import Typography from "../pages/components/Typography";
import AdvancedControls from "../pages/components/forms/AdvancedControls";
import CheckboxRadio from "../pages/components/forms/CheckboxRadio";
import InputGroup from "../pages/components/forms/InputGroup";
import FormUpload from "../pages/components/forms/FormUpload";
import NumberSpinner from "../pages/components/forms/NumberSpinner";
import NouiSlider from "../pages/components/forms/nouislider";
import WizardForm from "../pages/components/forms/WizardForm";
import UtilBorder from "../pages/components/UtilBorder";
import UtilColors from "../pages/components/UtilColors";
import UtilDisplay from "../pages/components/UtilDisplay";
import UtilEmbeded from "../pages/components/UtilEmbeded";
import UtilFlex from "../pages/components/UtilFlex";
import UtilOthers from "../pages/components/UtilOthers";
import UtilSizing from "../pages/components/UtilSizing";
import UtilSpacing from "../pages/components/UtilSpacing";
import UtilText from "../pages/components/UtilText";

import Blank from "../pages/others/Blank";
import Faq from "../pages/others/Faq";
import Regularv1 from "../pages/others/Regular-1";
import Regularv2 from "../pages/others/Regular-2";
import Terms from "../pages/others/Terms";
import BasicTable from "../pages/components/table/BasicTable";
import DataTablePage from "../pages/components/table/DataTable";
import SpecialTablePage from "../pages/components/table/SpecialTable";
import ChartPage from "../pages/components/charts/Charts";
import KnobPreview from "../pages/components/charts/KnobPreview";
import EmailTemplate from "../pages/components/email-template/Email";
import NioIconPage from "../pages/components/crafted-icons/NioIcon";
import SVGIconPage from "../pages/components/crafted-icons/SvgIcons";

import ProjectCardPage from "../pages/pre-built/projects/ProjectCard";
import ProjectListPage from "../pages/pre-built/projects/ProjectList";
import UserListRegular from "../pages/pre-built/user-manage/UserListRegular";
import UserContactCard from "../pages/pre-built/user-manage/UserContactCard";
import UserDetails from "../pages/pre-built/user-manage/UserDetailsRegular";
import UserListCompact from "../pages/pre-built/user-manage/UserListCompact";
import UserProfileRegular from "../pages/pre-built/user-manage/UserProfileRegular";
import UserProfileSetting from "../pages/pre-built/user-manage/UserProfileSetting";
import UserProfileNotification from "../pages/pre-built/user-manage/UserProfileNotification";
import UserProfileActivity from "../pages/pre-built/user-manage/UserProfileActivity";
import KycListRegular from "../pages/pre-built/kyc-list-regular/KycListRegular";
import KycDetailsRegular from "../pages/pre-built/kyc-list-regular/kycDetailsRegular";
import TransListBasic from "../pages/pre-built/trans-list/TransListBasic";
import TransListCrypto from "../pages/pre-built/trans-list/TransListCrypto";
import ProductCard from "../pages/pre-built/products/ProductCard";
import ProductList from "../pages/pre-built/products/ProductList";
import ProductDetails from "../pages/pre-built/products/ProductDetails";
import InvoiceList from "../pages/pre-built/invoice/InvoiceList";
import CustomerReportsList from "../pages/pre-built/reports/customer-reports";
import PaypalTransReport from "../pages/pre-built/reports/paypal-trans-report";
import InvoiceDetails from "../pages/pre-built/invoice/InvoiceDetails";
import InvoicePrint from "../pages/pre-built/invoice/InvoicePrint";
import PricingTable from "../pages/pre-built/pricing-table/PricingTable";
import GalleryPreview from "../pages/pre-built/gallery/GalleryCardPreview";

import FileManager from "../pages/app/file-manager/FileManager";
import FileManagerFiles from "../pages/app/file-manager/FileManagerFiles";
import FileManagerShared from "../pages/app/file-manager/FileManagerShared";
import FileManagerStarred from "../pages/app/file-manager/FileManagerStarred";
import FileManagerRecovery from "../pages/app/file-manager/FileManagerRecovery";
import FileManagerSettings from "../pages/app/file-manager/FileManagerSettings";
import AppMessages from "../pages/app/messages/Messages";
import Chat from "../pages/app/chat/ChatContainer";
import Kanban from "../pages/app/kanban/Kanban";
import Inbox from "../pages/app/inbox/Inbox";
import Calender from "../pages/app/calender/Calender";
import DateTimePicker from "../pages/components/forms/DateTimePicker";
import QuillPreview from "../pages/components/forms/rich-editor/QuillPreview";
import TinymcePreview from "../pages/components/forms/rich-editor/TinymcePreview";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Success from "../pages/auth/Success";

import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import TeamAuctionList from "../pages/pre-built/auction/team-auction";
import TeamIPOList from "../pages/pre-built/auction/team-ipo";
import BuyTeamShare from "../pages/pre-built/auction/buy-team-shares";
import MemberTeamShareHoldings from "../pages/pre-built/holdings/member-team-share-list";
import MemberTeamShareHoldingsTransaction from "../pages/pre-built/holdings/member-team-share-transaction";
import SellTeamShare from "../pages/pre-built/auction/sell-team-shares";
import OrdersHistory from "../pages/pre-built/orders";
import TeamBoardRoom from "../pages/pre-built/board-room/team-borad-room";
import DonateTeamRuns from "../pages/pre-built/donation/donation-alert";
import MemberDonationList from "../pages/pre-built/donation/donation-list";
import FavoriteTeamList from "../pages/pre-built/favorite-team/favorite-team-list";
import AddFavoriteTeam from "../pages/pre-built/favorite-team/add-favorite-team-list";
import DonateFractionRuns from "../pages/pre-built/fraction-runs/donate-fraction-runs";
import MemberTeamRentList from "../pages/pre-built/rent/rent-report";
import ValidatePin from "../pages/auth/validate-pin";
import GeneratePin from "../pages/auth/generate-pin";
import TeamRentReportList from "../pages/pre-built/rent/team-rent-report";

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  

  return (
      <Routes>

        <Route path={`${process.env.PUBLIC_URL}`} element={<Layout />}>
            <Route path="dashboard" element={<CryptoDashboard />}></Route>
            <Route path="accounts" element={<Account />}></Route>
            <Route path="wallets" element={<Wallets />}></Route>
            <Route path="walletsbtc" element={<WalletsBtc />}></Route>
            <Route path="buy-sell" element={<BuySell />}></Route>
            <Route path="buy-team-share" element={<BuyTeamShare />}></Route>
            <Route path="sell-team-share" element={<SellTeamShare />}></Route>
            <Route path="team-shares-holdings" element={<MemberTeamShareHoldings />}></Route>
            <Route path="team-shares-holdings-transaction" element={<MemberTeamShareHoldingsTransaction />}></Route>
            <Route path="customer-report-list" element={<CustomerReportsList />}></Route>
            <Route path="paypal-report-list" element={<PaypalTransReport />}></Route>
            <Route path="team-auction-list" element={<TeamAuctionList />}></Route>
            <Route path="team-ipo-list" element={<TeamIPOList />}></Route>
            <Route path="order-history" element={<OrdersHistory />}></Route>
            <Route path="add-favorite-team" element={<AddFavoriteTeam />}></Route>
            <Route path="favorite-team-list" element={<FavoriteTeamList />}></Route>
            <Route path="donate-fraction-runs" element={<DonateFractionRuns />}></Route>
            <Route path="donation-report" element={<MemberDonationList />}></Route>
            <Route path="rent-report" element={<MemberTeamRentList />}></Route>
            <Route path="team-rent-report" element={<TeamRentReportList />}></Route>
            <Route path="team-shares-board-room" element={<TeamBoardRoom />}></Route>
            <Route path="pay-rent-team-runs" element={<DonateTeamRuns />}></Route>
            <Route path="chat" element={<PanelChat />}></Route>
            <Route path="welcome" element={<Welcome />}></Route>
            <Route path="kyc-application" element={<KycApplications />}></Route>
            <Route path="kyc-form" element={<KycForms />}></Route>
            <Route path="" element={<CryptoUserProfileLayout />}>
              <Route path="user-profile-regular" element={<CryptoUserProfileRegular />}></Route>
              <Route path="user-profile-notification" element={<CryptoUserProfileNotification />}></Route>
              <Route path="user-profile-connected" element={<CryptoUserProfileConnected />}></Route>
              <Route path="user-profile-setting" element={<CryptoUserProfileSetting />}></Route>
            </Route>
            <Route path="user-profile-activity" element={<CryptoUserProfileActivity />}></Route>
          

          <Route path="project-card" element={<ProjectCardPage />}></Route>
          <Route path="project-list" element={<ProjectListPage />}></Route>

          
          <Route element={<UserContextProvider />} >
            <Route path="user-list-regular" element={<UserListRegular />}></Route>
            <Route path="user-list-compact" element={<UserListCompact />}></Route>
            <Route path="user-contact-card" element={<UserContactCard />}></Route>
            <Route path="user-details-regular/:userId" element={<UserDetails />}></Route>
          </Route>

          <Route >
            <Route path="user-profile-notification" element={<UserProfileNotification />} ></Route>
            <Route path="user-profile-regular" element={<UserProfileRegular />}></Route>
            <Route path="user-profile-activity" element={<UserProfileActivity />}></Route>
            <Route path="user-profile-setting" element={<UserProfileSetting />}></Route>
          </Route>

          <Route path="kyc-list-regular" element={<KycListRegular />}></Route>
          <Route path="kyc-details-regular/:kycId" element={<KycDetailsRegular />}></Route>
          <Route path="transaction-basic" element={<TransListBasic />}></Route>
          <Route path="transaction-crypto" element={<TransListCrypto />}></Route>
          <Route element={<ProductContextProvider />}>
            <Route path="product-list" element={<ProductList />}></Route>
            <Route path="product-card" element={<ProductCard />}></Route>
            <Route path="product-details/:productId" element={<ProductDetails />}></Route>
          </Route>


          
          <Route path="invoice-list" element={<InvoiceList />}></Route>
          <Route path="invoice-details/:invoiceId" element={<InvoiceDetails />}></Route>
          <Route path="pricing-table" element={<PricingTable />}></Route>
          <Route path="image-gallery" element={<GalleryPreview />}></Route>

          <Route path="pages">
            <Route path="terms-policy" element={<Terms />}></Route>
            <Route path="faq" element={<Faq />}></Route>
            <Route path="regular-v1" element={<Regularv1 />}></Route>
            <Route path="regular-v2" element={<Regularv2 />}></Route>
          </Route>

          <Route path="app-messages" element={<AppMessages />}></Route>
          <Route path="app-chat" element={<Chat />}></Route>
          <Route path="app-calender" element={<Calender />}></Route>
          <Route path="app-inbox" element={<Inbox />}></Route>
          <Route path="app-kanban" element={<Kanban />}></Route>

          <Route path="app-file-manager">
            <Route index element={<FileManager />}></Route>
            <Route path="files" element={<FileManagerFiles />}></Route>
            <Route path="starred" element={<FileManagerStarred />}></Route>
            <Route path="shared" element={<FileManagerShared />}></Route>
            <Route path="recovery" element={<FileManagerRecovery />}></Route>
            <Route path="settings" element={<FileManagerSettings />}></Route>
          </Route>

          
        </Route>
        <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
          <Route path="auth-success" element={<Success />}></Route>
            <Route path="auth-reset" element={<ForgotPassword />}></Route>
            <Route path="auth-register" element={<Register />}></Route>
            <Route path="auth-login" element={<Login />}></Route>
            <Route path="validate-pin" element={<ValidatePin />}></Route>
            <Route path="generate-pin" element={<GeneratePin />}></Route>

            <Route path="errors">
              <Route path="404-modern" element={<Error404Modern />}></Route>
              <Route path="404-classic" element={<Error404Classic />}></Route>
              <Route path="504-modern" element={<Error504Modern />}></Route>
              <Route path="504-classic" element={<Error504Classic />}></Route>
            </Route>
            <Route path="*" element={<Error404Modern />}></Route>
            
            <Route path="invoice-print/:invoiceId" element={<InvoicePrint />}></Route>
        </Route>

      </Routes>
  );
};
export default Pages;
