import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { UncontrolledDropdown, Alert, CardBody, Row, Col, DropdownMenu, DropdownToggle, Card, Badge, DropdownItem } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PaginationComponent,
} from "../../../components/Component";
import { Link, useNavigate } from "react-router-dom";
import useApiService from "../../../hooks/apiService";
import { useSelector } from "react-redux";

const MemberTeamShareHoldings = () => {
  const navigate = useNavigate();
  const { apiService, toastMessage, setToastMessage } = useApiService();
  const { userInfo } = useSelector((state) => state.menu)
  const [data, setData] = useState([]);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("asc");
  const [isTeamShareHoldingLoading, setIsTeamShareHoldingsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [sessionExpired, setSessionExpired] = useState(false);

  const fetchTeamShareHoldings = async () => {
    try {
      setIsTeamShareHoldingsLoading(true); // Show loader when fetching data

      const params = {
        page: currentPage,
        limit: itemPerPage,
        order: sort,
        filterName: onSearchText,
        member_id: userInfo?.id
      };

      console.log("params", params);

      const { result, success, message, total } = await apiService(
        'GET',
        '/fetch-member-team-share-holding', // Adjust the endpoint if necessary
        null,
        params
      );

      console.log("get recharge res", total, result)
      if (success) {
        setData(result);
        setTotalRows(total);
      } else if (result?.length === 0) {
        setData([]);
        handleApiError('No Wallet report found...');
      } else if (message === 'Failed to authenticate') {
        handleApiError('Failed to authenticate');
        setSessionExpired(true);
        setData([]);
      } else {
        handleApiError(message);
        setData([]);
      }
    } catch (error) {
      console.error('Failed to fetch matches:', error);
      handleApiError(error);
    } finally {
      setIsTeamShareHoldingsLoading(false); // Hide loader after data is fetched or an error occurs
    }
  };

  // Sorting data
  const sortFunc = () => {
    let defaultData = [...data];
    if (sort === "dsc") {
      defaultData.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    } else if (sort === "asc") {
      defaultData.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
    }
    setData(defaultData);
  };

  // Changing state value when searching name
  useEffect(() => {
    fetchTeamShareHoldings(); // Fetch data when component mounts or dependencies change
  }, [currentPage, itemPerPage, sort, onSearchText]);

  // Get current list, pagination
  // const indexOfLastItem = currentPage * itemPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemPerPage;
  // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const handleApiError = (message) => {
    // Handle API errors here
    alert(message);
  };

  return (
    <React.Fragment>
      <Head title="Wallet Request List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Holdings</BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {totalRows} holdings.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {
          data?.length > 0 &&
          <Card style={{ backgroundColor: "#ebf9f5" }}>
            <CardBody>
              <Row>
                <Col md={6}>
                  {/* Calculate and display Current Value */}
                  <h3 className="text-primary">
                    {Number(data.reduce((acc, item) => Number(acc) + Number((item.share_value || 0)), 0))?.toFixed(2)}
                  </h3>
                  <p>Current Value</p>
                </Col>
                <Col md={6}>
                  <div className="text-right">
                    {/* Calculate and display Invested Value */}
                    <p>
                      Invested Value:
                      {Number(data.reduce((acc, item) => Number(acc) + Number((item.total_shares * (item.avg_runs || 0))), 0))?.toFixed(2)}
                    </p>

                    {/* Calculate and display Total Returns */}
                    <p>
                      Total Returns:
                      {(() => {
                        const investedValue = data.reduce(
                          (acc, item) => Number(acc) + Number(item.total_shares * (item.avg_runs || 0)),
                          0
                        );
                        const currentValue = data.reduce(
                          (acc, item) => Number(acc) + Number((item.share_value || 0)),
                          0
                        );
                        const totalReturns = currentValue - investedValue;
                        const returnPercentage =
                          investedValue > 0 ? (totalReturns / investedValue) * 100 : 0;
                        return (
                          <>
                            {totalReturns >= 0 ? "+" : "-"}
                            {Math.abs(totalReturns).toFixed(2)}
                            ({returnPercentage.toFixed(2)}%)
                          </>
                        );
                      })()}
                    </p>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        }



        <Block>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h5 className="title">All Holdings</h5>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                          <Icon name="search"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <UncontrolledDropdown>
                          <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                            <Icon name="setting"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end>
                            <ul className="link-check">
                              <li>
                                <span>Show</span>
                              </li>
                              <li className={itemPerPage === 10 ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setItemPerPage(10);
                                  }}
                                >
                                  10
                                </DropdownItem>
                              </li>
                              <li className={itemPerPage === 15 ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setItemPerPage(15);
                                  }}
                                >
                                  15
                                </DropdownItem>
                              </li>
                            </ul>
                            <ul className="link-check">
                              <li>
                                <span>Order</span>
                              </li>
                              <li className={sort === "dsc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSortState("dsc");
                                    sortFunc();
                                  }}
                                >
                                  DESC
                                </DropdownItem>
                              </li>
                              <li className={sort === "asc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSortState("asc");
                                    sortFunc();
                                  }}
                                >
                                  ASC
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    </ul>
                  </div>
                  <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                    <div className="search-content">
                      <Button
                        className="search-back btn-icon toggle-search"
                        onClick={() => {
                          setSearchText("");
                          toggle();
                        }}
                      >
                        <Icon name="arrow-left"></Icon>
                      </Button>
                      <input
                        type="text"
                        className="form-control border-transparent form-focus-none"
                        placeholder="Search by Order Id"
                        value={onSearchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                      <Button className="search-submit btn-icon">
                        <Icon name="search"></Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-inner p-0">
                <table className="table table-orders">
                  <thead>
                    <tr className="tb-item-head">
                      <th className="tb-col"><span className="overline-title">Team Name</span></th>
                      <th className="tb-col"><span className="overline-title">Qty</span></th>
                      <th className="tb-col"><span className="overline-title">Bought (Runs/share)</span></th>
                      <th className="tb-col"><span className="overline-title">Total Invested Runs</span></th>
                      {/* <th className="tb-col"><span className="overline-title">Market (Runs)</span></th>
                      <th className="tb-col"><span className="overline-title">Current (Runs)</span></th> */}
                      <th className="tb-col"><span className="overline-title">Action</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isTeamShareHoldingLoading ? (
                      <tr>
                        <td colSpan="5" className="text-center">Loading...</td>
                      </tr>
                    ) : (
                      data?.length > 0 ?
                        data.map((item, index) => (
                          <tr key={index}>
                            <td className="tb-col">
                              <div>{item?.team_name}</div>
                              {
                                item?.team_wallet <= item?.lower_limit &&
                                <div>
                                  <Alert color="danger" className="alert-icon">
                                    <Icon name="alert-circle" />
                                    <Button size="sm" className="btn-block" color="primary"
                                      onClick={() => navigate("/pay-rent-team-runs", {
                                        state: item
                                      })}>
                                      Pay Rent
                                    </Button>
                                  </Alert>
                                </div>
                              }

                            </td>
                            <td className="tb-col">{item?.total_shares}</td>
                            <td className="tb-col">{item?.market_price || 0}</td>
                            <td className="tb-col">{Number(item?.total_shares * (item?.market_price || 0))?.toFixed(2)}</td>
                            {/* <td className="tb-col">{item?.market_price || 0}</td> */}
                            {/* <td className="tb-col">
                              <div className="nk-tnx-type-text">
                                <div className="tb-lead" style={{ color: (item?.total_shares * (item?.avg_runs || 0)) <= (item?.total_shares * (item?.market_price || 0)) ? "#00b386" : "#ec2e2e" }} >{(item?.total_shares * (item?.market_price || 0))}</div>
                                <div className="tb-date">{item?.total_shares} (Total Shares) * {item?.market_price || 0} (Market Runs Per share) =  {(item?.total_shares * (item?.market_price || 0))}</div>
                              </div>

                            </td> */}
                            <td className="tb-col">
                              <Button onClick={() => navigate("/sell-team-share", {
                                state: item
                              })} color="primary" size="md"
                                disabled={item?.team_wallet <= item?.lower_limit}>
                                Sell
                              </Button>
                            </td>
                          </tr>
                        ))
                        :
                        <tr>
                          <td colSpan="5" className="text-center mt-5">No Record Found!</td>
                        </tr>
                    )}
                  </tbody>
                </table>
                {data.length > 0 && (
                  <PaginationComponent
                    itemPerPage={itemPerPage}
                    totalItems={totalRows}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                )}
              </div>
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default MemberTeamShareHoldings;
