import React, { useEffect } from "react";
import { setDateForPicker } from "../../../utils/Utils";
import Select from 'react-select';
import { Row, Col, Modal, ModalBody, ModalHeader, Button, Spinner } from "reactstrap";
import DatePicker from "react-datepicker";
import { BlockBetween } from "../../../components/Component";

const AddEditMeetingForm = ({
  modal,
  onFormCancel,
  handleSubmit,
  handleFormSubmit,
  register,
  formData,
  setFormData,
  errors,
  setSelectedMembers,
  members,
  selectedMembers,
  isMeetingLoading,
}) => {

  // Populate selected members from formData (board_member)
  useEffect(() => {
    if (formData.board_member && formData.board_member.length > 0) {
      const selected = formData.board_member.map(member => ({
        value: member.id, 
        label: `${member.name} ${member.lname || ""}`.trim(),
      }));
      setSelectedMembers(selected);
    //   setFormData({ ...formData, memberIds: selected.map(member => member.value) });
    }
  }, [formData]);

  useEffect(()=>{

  },[formData])

  return (
    <Modal isOpen={modal} toggle={() => onFormCancel()} className="modal-md">
      <ModalHeader toggle={() => onFormCancel()}>
        {formData?.id ? "Edit Event" : "Add Event"}
      </ModalHeader>
      <ModalBody>
        <form className="form-validate is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
          <Row className="gx-4 gy-3">
            {/* Event Title */}
            <Col sm="12">
              <div className="form-group">
                <label className="form-label" htmlFor="event-title">Event Title</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="event-title"
                    {...register('meeting_title', { required: true })}
                    value={formData?.meeting_title || ''}  // Ensure default value is set
                    onChange={(e) => setFormData({ ...formData, meeting_title: e.target.value })}
                    className="form-control"
                  />
                  {errors.meeting_title && <p className="invalid">This field is required</p>}
                </div>
              </div>
            </Col>

            {/* Meeting Link */}
            <Col sm="12">
              <div className="form-group">
                <label className="form-label" htmlFor="meeting-link">Meeting Link</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="meeting-link"
                    {...register("meeting_link", { required: true })}
                    value={formData?.meeting_link || ""}
                    onChange={(e) => setFormData({ ...formData, meeting_link: e.target.value })}
                    className="form-control"
                  />
                  {errors.meeting_link && <p className="invalid">This field is required</p>}
                </div>
              </div>
            </Col>

            {/* Start Date & Time */}
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Start Date & Time</label>
                <Row className="gx-2">
                  <div className="w-55">
                    <div className="form-control-wrap">
                      <DatePicker
                        selected={formData?.start_time ? new Date(formData.start_time) : null}
                        onChange={(date) => setFormData({ ...formData, start_time: date })}
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>
                  </div>
                  <div className="w-45">
                    <div className="form-control-wrap">
                      <DatePicker
                        selected={formData?.start_time ? new Date(formData?.start_time) : null}
                        onChange={(time) => setFormData({ ...formData, startTime: time })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control"
                      />
                    </div>
                  </div>
                </Row>
              </div>
            </Col>

            {/* End Date & Time */}
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">End Date & Time</label>
                <Row className="gx-2">
                  <div className="w-55">
                    <div className="form-control-wrap">
                      <DatePicker
                        selected={formData?.end_time ? new Date(formData.end_time) : null}
                        onChange={(date) => setFormData({ ...formData, end_time: date })}
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>
                  </div>
                  <div className="w-45">
                    <div className="form-control-wrap">
                      <DatePicker
                        selected={formData?.end_time ? new Date(formData?.end_time) : null}
                        onChange={(time) => setFormData({ ...formData, endTime: time })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control"
                      />
                    </div>
                  </div>
                </Row>
              </div>
            </Col>

            {/* Description */}
            <Col sm="12">
              <div className="form-group">
                <label className="form-label" htmlFor="event-description">Description</label>
                <div className="form-control-wrap">
                  <textarea
                    id="event-description"
                    {...register("meeting_desc")}
                    value={formData?.meeting_desc || ""}
                    onChange={(e) => setFormData({ ...formData, meeting_desc: e.target.value })}
                    className="form-control"
                  />
                </div>
              </div>
            </Col>

            {/* Select Members */}
            <Col sm="12">
              {members?.length > 0 && (
                <div className="form-group">
                  <label className="form-label">Select Members</label>
                  <Select
                    id="members"
                    options={members}
                    isMulti
                    value={selectedMembers}
                    onChange={(selected) => {
                      setSelectedMembers(selected);
                      setFormData({ ...formData, memberIds: selected.map((member) => member.value) });
                    }}
                    placeholder="Select Members"
                  />
                </div>
              )}
            </Col>
          </Row>
          <BlockBetween className="g-3 mt-5">
            <Button color="primary" type="submit">
              {isMeetingLoading ? <Spinner /> : "Submit"}
            </Button>
            <Button color="light" onClick={onFormCancel}>Cancel</Button>
          </BlockBetween>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddEditMeetingForm;
