import React from "react";
import { Pagination, PaginationLink, PaginationItem } from "reactstrap";
import Icon from "../icon/Icon";

const PaginationComponent = ({ itemPerPage, totalItems, paginate, currentPage }) => {
  
  const pageNumbers = [];

  // Calculate total number of pages
  for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
    pageNumbers.push(i);
  }

  // Define logic for how the pagination numbers should appear
  const paginationNumber = () => {
    if (pageNumbers.length <= 5) {
      return pageNumbers;
    } else if (pageNumbers.length >= 5 && currentPage <= 4) {
      return [1, 2, 3, 4, 5, '...', pageNumbers[pageNumbers.length - 1]];
    } else if (pageNumbers.length >= 5 && currentPage >= pageNumbers[pageNumbers.length - 4]) {
      return [1, '...', pageNumbers[pageNumbers.length - 5], pageNumbers[pageNumbers.length - 4], pageNumbers[pageNumbers.length - 3], pageNumbers[pageNumbers.length - 2], pageNumbers[pageNumbers.length - 1]];
    } else if (pageNumbers.length > 5 && currentPage > 4 && currentPage < pageNumbers[pageNumbers.length - 4]) {
      return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', pageNumbers[pageNumbers.length - 1]];
    }
  };

  // Get the pagination items based on current page
  let paginationItems = paginationNumber();

  // Handlers for first, previous, next, and last page actions
  const firstPage = () => {
    paginate(1);
  };

  const lastPage = () => {
    paginate(pageNumbers[pageNumbers.length - 1]);
  };

  const nextPage = () => {
    paginate(currentPage + 1);
  };

  const prevPage = () => {
    paginate(currentPage - 1);
  };

  return (
    <Pagination aria-label="Page navigation example">
      {/* First Page */}
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink
          className="page-link-first"
          onClick={(ev) => {
            ev.preventDefault();
            firstPage();
          }}
          href="#first"
        >
          <Icon name="chevrons-left" />
        </PaginationLink>
      </PaginationItem>

      {/* Previous Page */}
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink
          className="page-link-prev"
          onClick={(ev) => {
            ev.preventDefault();
            prevPage();
          }}
          href="#prev"
        >
          <Icon name="chevron-left" />
        </PaginationLink>
      </PaginationItem>

      {/* Pagination Numbers */}
      {paginationItems.map((item, index) => (
        <PaginationItem
          disabled={isNaN(item)}
          className={`d-none d-sm-block ${currentPage === item ? "active" : ""}`}
          key={index}
        >
          <PaginationLink
            tag="a"
            href="#pageitem"
            onClick={(ev) => {
              ev.preventDefault();
              if (!isNaN(item)) {
                paginate(item);
              }
            }}
          >
            {item}
          </PaginationLink>
        </PaginationItem>
      ))}

      {/* Next Page */}
      <PaginationItem disabled={currentPage === pageNumbers[pageNumbers.length - 1]}>
        <PaginationLink
          className="page-link-next"
          onClick={(ev) => {
            ev.preventDefault();
            nextPage();
          }}
          href="#next"
        >
          <Icon name="chevron-right" />
        </PaginationLink>
      </PaginationItem>

      {/* Last Page */}
      <PaginationItem disabled={currentPage === pageNumbers[pageNumbers.length - 1]}>
        <PaginationLink
          className="page-link-last"
          onClick={(ev) => {
            ev.preventDefault();
            lastPage();
          }}
          href="#last"
        >
          <Icon name="chevrons-right" />
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

export default PaginationComponent;
