import React, { useEffect } from 'react';
import { Toast, ToastBody, ToastHeader, Button } from 'reactstrap';

const CustomToast = ({ open, handleClose, severity, message, duration = 5000 }) => {
  // Function to determine the alert color based on severity
 console.log("toast console", open,message, severity)
  const getAlertColor = () => {
    switch (severity) {
      case 'success':
        return 'success';
      case 'error':
        return 'danger';
      case 'warning':
        return 'warning';
      case 'info':
      default:
        return 'info';
    }
  };

  // Automatically close the toast after the specified duration (5 seconds)
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        handleClose();
      }, duration); // Close after 5 seconds
      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [open, handleClose, duration]);

  return (
    open && message && (
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 1050, right: 0, top: '90px' }}>
        <Toast isOpen={open}>
          <ToastHeader toggle={handleClose} className={`bg-${getAlertColor()}`}>
            {severity.charAt(0).toUpperCase() + severity.slice(1)} {/* Display the severity type */}
          </ToastHeader>
          <ToastBody>
            {message}
          </ToastBody>
        </Toast>
      </div>
    )
  );
};

export default CustomToast;
