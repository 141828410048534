import { useSelector } from 'react-redux';
import useApiService from './apiService';
import { useDispatch } from 'react-redux';
import { accountLockStatusDetails } from '../redux/reducer/menu';
// Define the function to fetch wallet recharge reports
export const UseAccountLockStatus = () => {
const { apiService, toastMessage, setToastMessage } = useApiService();
const dispatch = useDispatch();
  const { userInfo } = useSelector((state)=>state.menu)

  const handleApiError = (err) => {
    setToastMessage({
      message: err,
      toastType: "error"
    });
  }

  // Function to fetch wallet recharge reports
  const accountLockStatus = async () => {
    try {
      // Define the parameters to be sent in the API request
      const params = {
        member_id: userInfo?.id,
      };

      console.log('params', params);

      // Make the API call using apiService
      const { result, success, message } = await apiService(
        'GET',
        '/check-account-lock-status', // Adjust the endpoint if necessary
        null,
        params
      );

      console.log('get recharge res',result, success, message);

      // Check the success flag and update the state accordingly
      if (success) {
        if(result?.legth === 0){
      console.log('get recharge res',result, success, message);

          dispatch(accountLockStatusDetails(false))
        }else{
      console.log('get recharge res',result, success, message);

          const givenDate = new Date(result); // Parse the date from API
          const currentDate = new Date(); // Get current date and time
        
          // Calculate the difference in milliseconds
          const timeDifference = currentDate - givenDate;
        
          // 24 hours in milliseconds = 24 * 60 * 60 * 1000
          const hours24InMillis = 24 * 60 * 60 * 1000;
          dispatch(accountLockStatusDetails(timeDifference > hours24InMillis))
        }
        
        return true;
      } else if (message === 'Failed to authenticate') {
        handleApiError('Failed to authenticate');
        return false
      } else {
        handleApiError(message);
        return false
      }
    } catch (error) {
      console.error('Failed to fetch wallet recharge report:', error);
      handleApiError(error.message || 'Error occurred while fetching data');
      return false
    } 
  };

  return {
    accountLockStatus,
  };
};
