import React, { useState, useEffect } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useApiService from "../../hooks/apiService";
import { userDetails, login, walletDetails } from "../../redux/reducer/menu";
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../config/config";
import CustomToast from "../../components/toast";

const ValidatePin = () => {
  const { apiService, toastMessage, setToastMessage } = useApiService();
  const dispatch = useDispatch();
  const location = useLocation()
  const [inputPin, setInputPin] = useState(""); // state to store pin
  const [isValidatePinLoading, setIsValidatePinLoading] = useState(false);

  // Function to validate the user's pin
  const validatePin = async () => {
    const requestPayload = {
      pin: inputPin,
      id: location?.state?.id
      // email: 'rohit_1@gmail.com',
    };

    try {
      setIsValidatePinLoading(true);
      const response = await axios.post(`${baseUrl}/validate-pin`, requestPayload);

      if (response?.data?.success) {
        setToastMessage({
          message: "Pin Validate Successfully",
          toastType: "success",
        });

        dispatch(login(response?.data?.token));
        dispatch(userDetails(response?.data?.user));
        dispatch(walletDetails(response?.data?.wallet));
        setTimeout(() => {
          window.history.pushState(
            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/dashboard"}`,
            "auth-login",
            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/dashboard"}`
          );
          window.location.reload();
        }, 1000);
      } else {
        setToastMessage({
          message: response?.data?.message,
          toastType: "error",
        });
      }
    } catch (err) {
      setToastMessage({
        message: err?.response?.data?.message || 'An error occurred',
        toastType: "error",
      });
    } finally {
      setIsValidatePinLoading(false);
    }
  };

  const handleClose = () => {
    setToastMessage(null);
  };


  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <>
      <Head title="Login" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>

          <>
            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h4">Enter Pin</BlockTitle>
                  <BlockDes>
                    <p>Access Dashlite using your Pin.</p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>

              <Form className="is-alter" onSubmit={(e) => { e.preventDefault(); validatePin(); }}>
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="pin">Enter Pin</label>
                  </div>
                  <div className="form-control-wrap">
                    <input
                      type="password"
                      id="pin"
                      value={inputPin}
                      onChange={(e) => setInputPin(e.target.value)}
                      placeholder="Enter your pin"
                      className="form-control-lg form-control"
                      required
                    />
                  </div>
                </div>

                <div className="form-group">
                  <Button size="lg" className="btn-block" type="submit" color="primary">
                    {isValidatePinLoading ? <Spinner size="sm" color="light" /> : "Go"}
                  </Button>
                </div>
              </Form>
            </PreviewCard>
          </>
      </Block>
      <AuthFooter />
      <CustomToast
        open={toastMessage?.toastType ? true : false}
        handleClose={handleClose}
        severity={toastMessage?.toastType}
        message={toastMessage?.message}
      />
    </>
  );
};

export default ValidatePin;
