import { useSelector } from 'react-redux';
import useApiService from './apiService';
import { useDispatch } from 'react-redux';
import { walletDetails } from '../redux/reducer/menu';
// Define the function to fetch wallet recharge reports
export const UseMemberWalletBalanceUpdate = () => {
const { apiService, toastMessage, setToastMessage } = useApiService();
const dispatch = useDispatch();
  const { userInfo } = useSelector((state)=>state.menu)

  const handleApiError = (err) => {
    setToastMessage({
      message: err,
      toastType: "error"
    });
  }

  // Function to fetch wallet recharge reports
  const updateMemberWalletBalance = async () => {
    try {
      // Define the parameters to be sent in the API request
      const params = {
        member_id: userInfo?.id,
      };

      console.log('params', params);

      // Make the API call using apiService
      const { result, success, message } = await apiService(
        'GET',
        '/fetch-member-wallet-balance', // Adjust the endpoint if necessary
        null,
        params
      );

      console.log('get recharge res',result, success, message);

      // Check the success flag and update the state accordingly
      if (success) {
        dispatch(walletDetails(result[0]))
        return true;
      } else if (message === 'Failed to authenticate') {
        handleApiError('Failed to authenticate');
        return false
      } else {
        handleApiError(message);
        return false
      }
    } catch (error) {
      console.error('Failed to fetch wallet recharge report:', error);
      handleApiError(error.message || 'Error occurred while fetching data');
      return false
    } 
  };

  return {
    updateMemberWalletBalance,
  };
};
