import React, { useState, useEffect } from "react";
import ContentAlt from "../../../layout/content/ContentAlt";
import Head from "../../../layout/head/Head";
import CalenderApp from "../../../components/partials/calender/Calender";
import { Button,  ModalHeader, Modal, ModalBody, Row, Col, Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewAltCard,
} from "../../../components/Component";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { eventOptions, events } from "../../../components/partials/calender/CalenderData";
import { useForm } from "react-hook-form";
import CustomToast from "../../../components/toast";
import useApiService from "../../../hooks/apiService";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import moment from "moment";
import AddEditMeetingForm from "./add-edit-meeting";

const Calender = () => {
  const { apiService, toastMessage, setToastMessage } = useApiService();
  const { userInfo } = useSelector((state) => state.menu);
  const navigate = useNavigate()
  const [modal, setModal] = useState(false);
  const [mockEvents, updateEvent] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [formData, setFormData] = useState({
    meeting_title: "",
    meeting_desc: "",
    start_time: new Date(),
    startTime: new Date(),
    end_time: new Date(),
    endDate: new Date(),
    theme: {
      value: "fc-event-primary",
      label: "Company",
    },
    memberIds: [], // State to hold selected member IDs
  });
  const [theme, settheme] = useState("");
  const [members, setMembers] = useState([]); // State to hold members list
  const [isTeamMembersLoading, setIsTeamMembersLoading] = useState(false);
  const [isMeetingLoading, setIsMeetingLoading] = useState(false);
  const [isBoardMeetingListLoading, setIsBoardMeetingListLoading] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const { reset, register, handleSubmit, formState: { errors }, setError } = useForm();

  useEffect(() => {
    fetchMembers();
    fetchTeamBoardRoomMeetingList()
  }, []);

  const handleClose = () => {
    setToastMessage(null);
  };

  const handleApiError = (message) => {
    // Handle API errors here
    setToastMessage({
      message: message,
      toastType: "error"
    });
  };

  const transformApiDataToEvents = (apiData) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return apiData.map((meeting) => {
      const startDate = new Date(meeting.start_time);
      const endDate = new Date(meeting.end_time);

      const startFormatted = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, "0")}-${String(startDate.getDate()).padStart(2, "0")}`;
      const endFormatted = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`;

      return {
        ...meeting,
        id: `default-event-id-${meeting.id}`,
        title: meeting.meeting_title,
        start: startFormatted,
        end: endFormatted,
         className: meeting?.meeting_status === "scheduled" ? "fc-event-primary" : "fc-event-danger", // Adjust className based on meeting status or type if needed
        type: { value: "fc-event-primary", label: "Company" }, // Adjust type based on your requirements
        description: meeting.meeting_desc || meeting.description || "No description available",
        board_member: JSON.parse(meeting?.board_member)
      };
    });
  };

  const fetchMembers = async () => {
    try {
      setIsTeamMembersLoading(true); // Show loader when fetching data

      const params = {
        team_account_number: userInfo?.account_number,
      };

      console.log("params", params);

      const { result, success, message } = await apiService(
        'GET',
        '/fetch-board-room-team-members',
        null,
        params
      );

      console.log("get recharge res", result);
      if (success) {
        setMembers(result.map(member => ({
          value: member.id,
          label: member.name,
        })));
      } else if (result?.length === 0) {
        setMembers([]);
      } else if (message === 'Failed to authenticate') {
        handleApiError('Failed to authenticate');
        setSessionExpired(true);
        setMembers([]);
      } else {
        handleApiError(message);
        setMembers([]);
      }
    } catch (error) {
      console.error('Failed to fetch team members:', error);
      handleApiError(error);
    } finally {
      setIsTeamMembersLoading(false); // Hide loader after data is fetched or an error occurs
    }
  };

  const fetchTeamBoardRoomMeetingList = async () => {
    try {
      setIsBoardMeetingListLoading(true); // Show loader when fetching data

      const params = {
        team_account_number: userInfo?.account_number,
      };

      console.log("params", params);

      const { result, success, message } = await apiService(
        'GET',
        '/fetch-team-boardroom-meeting-list',
        null,
        params
      );

      console.log("get recharge res", result);
      if (success) {
        const eventsFromApi = transformApiDataToEvents(result);
        console.log("eventsFromApi", eventsFromApi);
        updateEvent(eventsFromApi)
      } else if (result?.length === 0) {
        updateEvent([]);
      } else if (message === 'Failed to authenticate') {
        handleApiError('Failed to authenticate');
        setSessionExpired(true);
        updateEvent([]);
      } else {
        handleApiError(message);
        updateEvent([]);
      }
    } catch (error) {
      console.error('Failed to fetch team members:', error);
      handleApiError(error);
    } finally {
      setIsBoardMeetingListLoading(false); // Hide loader after data is fetched or an error occurs
    }
  };

  const resetForm = () => {
    setFormData({
      title: "",
      description: "",
      meetingLink: "",
      startDate: new Date(),
      startTime: new Date(),
      endTime: new Date(),
      endDate: new Date(),
      theme: {
        value: "fc-event-primary",
        label: "Company",
      },
      memberIds: [], // Reset member IDs on form reset
    });
    setSelectedMembers([])
  };

  const onFormCancel = () => {
    setModal(false);
    resetForm();
  };

  const validateDates = () => {
    const start = new Date(formData?.startDate).setHours(
      formData?.startTime.getHours(),
      formData?.startTime.getMinutes()
    );
    const end = new Date(formData?.endDate).setHours(
      formData?.endTime.getHours(),
      formData?.endTime.getMinutes()
    );
    if (start > end) {
      setError("startDate", {
        type: "manual",
        message: "Start date/time cannot be greater than end date/time",
      });
      return false;
    }
    return true;
  };

  const handleFormSubmit = async (form) => {
    if (!validateDates()) return;
    console.log("formmdddata",formData,userInfo)
    setIsMeetingLoading(true)

    const startDateTime = moment(
      `${moment(formData.start_time).format('YYYY-MM-DD')} ${moment(formData.startTime).format('HH:mm:ss')}`
    ).format('YYYY-MM-DD HH:mm:ss');

    const endDateTime = moment(
      `${moment(formData.end_time).format('YYYY-MM-DD')} ${moment(formData.endTime).format('HH:mm:ss')}`
    ).format('YYYY-MM-DD HH:mm:ss');

    let url = formData?.id ? '/update-boardroom-schedule-meeting' : '/boardroom-schedule-meeting' 
    
    let newEvent = {
      team_id: userInfo?.id,
      title: formData.title ,
      meetingLink: formData.meetingLink,
      start: startDateTime, // Format to ISO string
      end: endDateTime, // Format to ISO string      
      description: formData.description,
      memberIds: formData.memberIds, // Include selected member IDs in the event
    };

    if(formData?.id){
      newEvent.meeting_id = formData?.id?.split('-').pop()
    }

    console.log("handleFormSubmit", newEvent)
    try {
      const { success, message } = await apiService(
        'POST',
        url, 
        newEvent 
      );

      // Handle success response
      if (success) {
        settheme({
          value: "fc-event-primary",
          label: "Company",
        });
        setModal(!modal);
        resetForm(); // Reset form data
        setToastMessage({
          message: "Meeting scheduled successfully!",
          toastType: "success"
        });
        fetchTeamBoardRoomMeetingList();
      } else if (message === "Failed to authenticate") {
        setToastMessage({
          message: "Failed to authenticate. Please login again to continue...",
          toastType: "error"
        });
        navigate('/');
      } else {
        handleApiError(message);
      }
    } catch (error) {
      console.error('Failed to schedule meeting:', error);
      handleApiError('An error occurred while scheduling the meeting.');
    } finally {
      setIsMeetingLoading(false)

    }
  };

  const editEvent = (formData) => {
    let newEvents = mockEvents;
    const index = newEvents.findIndex((item) => item.id === formData.id);
    events[index] = formData;
    updateEvent([...events]);
  };

  const deleteEvent = (id) => {
    let filteredEvents = mockEvents.filter((item) => item.id !== id);
    updateEvent(filteredEvents);
  };

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  return (
    <React.Fragment>
      <Head title="Calender" />
      <ContentAlt>
        <Block>
          <PreviewAltCard className="bg-transparent" noBorder bodyClass="py-3 border-bottom border-light rounded-0">
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle page>Calendar</BlockTitle>
                </BlockHeadContent>
                <BlockHeadContent>
                  <Button color="primary" onClick={toggle}>
                    <Icon name="plus" />
                    <span>Add Event</span>
                  </Button>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
          </PreviewAltCard>
          <Block>
            <PreviewAltCard noBorder>
              <CalenderApp
                events={mockEvents}
                onDelete={deleteEvent}
                onEdit={editEvent}
                handleSubmit={handleSubmit}
                handleFormSubmit={handleFormSubmit}
                register={register}
                formData={formData}
                setFormData={setFormData}
                errors={errors}
                setSelectedMembers={setSelectedMembers}
                members={members}
                selectedMembers={selectedMembers}
                isMeetingLoading={isMeetingLoading}
              />
            </PreviewAltCard>
          </Block>
        </Block>
      </ContentAlt>
{/* 
      <AddEditMeetingForm
        modal={modal}
        onFormCancel={onFormCancel}
        handleSubmit={handleSubmit}
        handleFormSubmit={handleFormSubmit}
        register={register}
        formData={formData}
        setFormData={setFormData}
        errors={errors}
        setSelectedMembers={setSelectedMembers}
        members={members}
        selectedMembers={selectedMembers}
        isMeetingLoading={isMeetingLoading}
        isEditable={false}
      /> */}
      <Modal isOpen={modal} toggle={() => onFormCancel()} className="modal-md">
        <ModalHeader toggle={() => onFormCancel()}>Add Event</ModalHeader>
        <ModalBody>
          <form className="form-validate is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
            <Row className="gx-4 gy-3">
              <Col sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="event-title">
                    Event Title
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="event-title"
                      {...register('title', { required: true })}
                      value={formData.title}
                      onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                      className="form-control" />
                    {errors.title && <p className="invalid">This field is required</p>}
                  </div>
                </div>
              </Col>
              <Col sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="meeting-link">
                    Meeting Link
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="meeting-link"
                      {...register('meetingLink', { required: true })}
                      value={formData.meetingLink} // Use meetingLink from formData
                      onChange={(e) => setFormData({ ...formData, meetingLink: e.target.value })}
                      className="form-control" />
                    {errors.meetingLink && <p className="invalid">This field is required</p>}
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Start Date &amp; Time</label>
                  <Row className="gx-2">
                    <div className="w-55">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={formData.startDate}
                          onChange={(date) => setFormData({ ...formData, startDate: date })}
                          className="form-control" />
                      </div>
                    </div>
                    <div className="w-45">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={formData.startTime}
                          onChange={(time) => setFormData({ ...formData, startTime: time })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control" />
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">End Date &amp; Time</label>
                  <Row className="gx-2">
                    <div className="w-55">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={formData.endDate}
                          onChange={(date) => setFormData({ ...formData, endDate: date })}
                          className="form-control" />
                      </div>
                    </div>
                    <div className="w-45">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={formData.endTime}
                          onChange={(time) => setFormData({ ...formData, endTime: time })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control" />
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="event-description">
                    Description
                  </label>
                  <div className="form-control-wrap">
                    <textarea
                      id="event-description"
                      {...register('description')}
                      value={formData.description}
                      onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                      className="form-control" />
                  </div>
                </div>
              </Col>
              <Col sm="12">
                {
                  console.log("members====", members)
                }
                {
                  members?.length > 0 &&
                  <div className="form-group">
                    <label className="form-label">Select Members</label>
                    <Select
                      id="members"
                      options={members}
                      isMulti
                      value={selectedMembers}
                      onChange={(selected) => {
                        setSelectedMembers(selected);
                        setFormData({ ...formData, memberIds: selected.map(member => member.value) });
                      }}
                      placeholder="Select chapters"
                    />
                  </div>
                }

              </Col>
            </Row>
            <BlockBetween className="g-3 mt-5">
              <Button color="primary" type="submit">{isMeetingLoading ? <Spinner /> : "Submit"}</Button>
              <Button color="light" onClick={onFormCancel}>Cancel</Button>
            </BlockBetween>
          </form>
        </ModalBody>
      </Modal>
      {toastMessage && (
        <CustomToast
          toastMessage={toastMessage.message}
          toastType={toastMessage.toastType}
          onClose={handleClose}
        />
      )}
    </React.Fragment>
  );
};

export default Calender;
