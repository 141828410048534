import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../../components/Component";
import { 
  Button, 
  Input, 
  Label,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Card,
  Badge
  } from "reactstrap";
import { Link } from "react-router-dom";
import BuyOrdersList from "./buy-order";
import SellOrdersList from "./sell-order";
import PendingOrdersList from "./pending-order";

const OrdersHistory = () => {
   // function to toggle the search option
   const toggle = () => setonSearch(!onSearch);
   const [onSearch, setonSearch] = useState(true);

   // State for active tab
   const [activeTab, setActiveTab] = useState("schedule");

   // Function to change the active tab
   const handleTabChange = (tab) => {
      setActiveTab(tab);
   };

  return (
    <React.Fragment>
      <Head title="Crypto Dashboard"></Head>
      <Content>
        <BlockHead>
          <BlockBetween size="md" className="g-4">
            <BlockHeadContent>
              <BlockTitle tag="h2" className="fw-normal">Your Orders</BlockTitle>
              <BlockDes>
                <p>See full list of your orders of your account</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {/* Tabs Navigation */}
        {/* <ul className="nk-nav nav nav-tabs">
          <li className="nav-item">
            <a 
              className={`nav-link ${activeTab === "buy" ? "active" : ""}`} 
              href="#" 
              onClick={(ev) => {
                ev.preventDefault();
                handleTabChange("buy");
              }}>
              Buy
            </a>
          </li>
          <li className="nav-item">
            <a 
              className={`nav-link ${activeTab === "sell" ? "active" : ""}`} 
              href="#" 
              onClick={(ev) => {
                ev.preventDefault();
                handleTabChange("sell");
              }}>
              Sells
            </a>
          </li>
          <li className="nav-item">
            <a 
              className={`nav-link ${activeTab === "schedule" ? "active" : ""}`} 
              href="#" 
              onClick={(ev) => {
                ev.preventDefault();
                handleTabChange("schedule");
              }}>
              Scheduled 
            </a>
          </li>
        </ul> */}

        {/* Tab Content */}
        <Block size="sm">
          <BlockHead size="sm">
            <div className={`search-wrap search-wrap-extend ${!onSearch ? "active" : ""}`}>
              <div className="search-content">
                <Link
                  to="#"
                  className="search-back btn btn-icon toggle-search"
                  onClick={() => {
                    toggle();
                  }}
                >
                  <Icon name="arrow-left"></Icon>
                </Link>
                <Input
                  type="text"
                  className="form-control form-control-sm border-transparent form-focus-none"
                  placeholder="Quick search by user"
                />
                <Link 
                  to="#"
                  className="search-submit btn btn-icon">
                  <Icon name="search"></Icon>
                </Link>
              </div>
            </div>
          </BlockHead>

          {/* Render content based on active tab */}
          {activeTab === "buy" && <BuyOrdersList />}
          {activeTab === "sell" && <SellOrdersList />}
          {activeTab === "schedule" && <PendingOrdersList />}

        </Block>
      </Content>
    </React.Fragment>
  );
};

export default OrdersHistory;
