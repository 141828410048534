import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { UserAvatar, Icon } from "../../../../../components/Component";
import { findUpper } from "../../../../../utils/Utils";
// import { contacts } from "./ContactData";
// import { chatData } from "../ChatData";
import { ContactItem } from "../ChatPartials";

import CustomToast from "../../../../../components/toast";
import useApiService from "../../../../../hooks/apiService";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";

const AppContact = ({ setTab, setSelectedId, fetchChatList }) => {
  const { apiService, toastMessage, setToastMessage } = useApiService();
  const { userInfo } = useSelector((state) => state.menu)
  const [contactData, setContactData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterText, setFilter] = useState("");

  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const [totalRows, setTotalRows] = useState(0); // Total rows for pagination
  const [itemPerPage] = useState(10); // Items per page (You can adjust the limit)
  const [isTeamMembersLoading, setIsTeamMembersLoading] = useState(false); // Loading state
  const [isStartChatLoading, setIsStartChatLoading] = useState(false); // Loading state
  useEffect(() => {
    if (filterText !== "") {
      const filteredObject = chatData.filter((item) => {
        return item.name.toLowerCase().includes(filterText.toLowerCase());
      });
      setFilterData([...filteredObject]);
    } else {
      setFilterData([]);
    }
  }, [filterText]);

  const handleApiError = (message) => {
    setToastMessage({
      message: message || "Network Error!",
      toastType: "error",
    });
  };

  const handleClose = () => {
    setToastMessage(null);
  };

  const fetchTeamMembersList = async () => {
    try {
      setIsTeamMembersLoading(true); // Show loader when fetching data
      const params = {
        page: currentPage,
        limit: itemPerPage,
        order: "asc", // Set sorting if required
        filterName: filterText,
        team_id: userInfo?.id,
      };

      const { result, success, message, total } = await apiService(
        "GET",
        "/fetch-team-board-room-list", // Adjust the endpoint if necessary
        null,
        params
      );

      console.log("Team Members lists=====", result)

      if (success) {
        setFilterData(result);
        setTotalRows(total); // Set total rows for pagination
      } else if (result?.length === 0) {
        handleApiError("No Wallet report found...");
      } else if (message === "Failed to authenticate") {
        handleApiError("Failed to authenticate");
        setSessionExpired(true);
      } else {
        handleApiError(message);
      }
    } catch (error) {
      console.error("Failed to fetch matches:", error);
      handleApiError(error);
    } finally {
      setIsTeamMembersLoading(false); // Hide loader after data is fetched or an error occurs
    }
  };

  useEffect(() => {
    fetchTeamMembersList();
  }, [currentPage, filterText]); // Fetch when currentPage or filterText changes

  const onInputChange = (e) => {
    setFilter(e.target.value);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalRows / itemPerPage);
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleStartChat = async (receiver) => {

    const chatObj = {
      sender_id: userInfo?.id,
      receiver_id: receiver?.member_id,
      is_group: false,
      message_text: "",
    }

    try {
      setIsStartChatLoading(true);

      const { result, success, message } = await apiService('POST', '/create-new-chat', chatObj);

      console.log("create chat response", result, success, message);

      if (success) {
        fetchChatList();
      } else if (message === "Failed to authenticate") {
        setToastMessage({
          message: "Failed to authenticate. Please login again to continue...",
          toastType: "error"
        });
        navigate('/');
      } else {
        setToastMessage({
          message: message,
          toastType: "error"
        });
      }
    } catch (err) {
      console.log("errrr====", err)
      setToastMessage({
        message: err || 'An error occurred',
        toastType: "error"
      });
    } finally {
      setIsStartChatLoading(false);
    }


  }

  return (
    <React.Fragment>
      <SimpleBar className="nk-chat-aside-body">
        <div className="nk-chat-aside-search">
          <div className="form-group">
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <Icon name="search"></Icon>
              </div>
              <input
                type="text"
                className="form-round form-control"
                id="default-03"
                placeholder="Search by name"
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
        </div>
        <div className="nk-chat-aside-panel nk-chat-contact">
          {isTeamMembersLoading ? (
            <div className="loading-spinner"><center><Spinner /></center></div>
          ) : filterData?.length === 0 ? (
            filterText ? (
              <div className="ms-5">No user</div>
            ) : (
              contactData.map((item, idx) => {
                return (
                  <ContactItem
                    key={idx}
                    item={item}
                    setTab={setTab}
                    setSelectedId={setSelectedId}
                  ></ContactItem>
                );
              })
            )
          ) : (
            <ul className="contacts-list">
              {filterData.map((contact, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <li
                      onClick={() => {
                        setTab("Chats");
                        setSelectedId(contact?.id);
                      }}
                    >
                      <div className="user-card">
                        <a href="#card" onClick={(ev) => ev.preventDefault()}>
                          <UserAvatar
                            text={findUpper(contact?.name)}
                            theme={contact?.theme}
                            image={contact?.image}
                          ></UserAvatar>
                          <div className="user-name">{contact?.name} {contact?.mname} {contact?.lname}</div>
                        </a>
                        <div className="user-actions">
                          {/* <Link to={`${process.env.PUBLIC_URL}/app-chat`}> */}
                          <Link onClick={() => {
                            setTab("Chats")
                            handleStartChat(contact);
                            setSelectedId(contact?.id)
                          }

                          }>
                            Start Chat
                          </Link>
                        </div>
                      </div>
                    </li>
                  </React.Fragment>
                );
              })}
            </ul>
          )}
        </div>
      </SimpleBar>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="btn btn-secondary"
        >
          Previous
        </button>
        <span className="current-page">
          Page {currentPage} of {Math.ceil(totalRows / itemPerPage)}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === Math.ceil(totalRows / itemPerPage)}
          className="btn btn-secondary"
        >
          Next
        </button>
      </div>

      <CustomToast
        open={toastMessage?.toastType ? true : false}
        handleClose={handleClose}
        severity={toastMessage?.toastType}
        message={toastMessage?.message}
      />
    </React.Fragment>
  );
};

export default AppContact;
