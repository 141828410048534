import React, { useState, useEffect } from "react";
import {
    Input,
    Form,
    Spinner,
    Label,
    Button,
    FormFeedback,
    Modal,
    ModalBody,
} from "reactstrap";
import { Block, BlockHead, BlockTitle, Icon } from "../../../components/Component";
import useApiService from "../../../hooks/apiService";
import CustomToast from "../../../components/toast";
import Content from "../../../layout/content/Content";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const DonateFractionRuns = () => {
    const { apiService, toastMessage, setToastMessage } = useApiService();
    const { userInfo, walletInfo } = useSelector((state) => state.menu);
    const navigate = useNavigate();
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState("");
    const [amount, setAmount] = useState("");
    const [teamError, setTeamError] = useState("");
    const [amountError, setAmountError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState({
        confirm: false,
    });

    useEffect(() => {
        // Fetch teams on component mount
        const fetchTeams = async () => {
            try {
                const params = {
                    member_id: userInfo?.id,
                };
                const { result, success, message } = await apiService("GET", "/fetch-all-favorite-team", null, params);
                if (success) {
                    setTeams(result || []);
                } else {
                    setToastMessage({ message, toastType: "error" });
                }
            } catch (error) {
                setToastMessage({ message: "Error fetching teams", toastType: "error" });
            }
        };

        fetchTeams();

        // Check walletInfo and extract fractional runs
        const walletValue = walletInfo?.wallet;
        if (walletValue) {
            const decimalPart = walletValue % 1;
            if (decimalPart > 0) {
                // Set fractional part in the amount field
                setAmount(decimalPart.toFixed(2));
            } else {
                // No fractional part, set amount to 0
                setAmount("0.00");
            }
        }
    }, []);

    const validateForm = () => {
        let isValid = true;

        // Validate team selection
        if (!selectedTeam) {
            setTeamError("Please select a team.");
            isValid = false;
        } else {
            setTeamError("");
        }

        // Validate amount input
        if (!amount || parseFloat(amount) === 0) {
            setAmountError("You don't have any fractional runs to donate.");
            isValid = false;
        } else {
            setAmountError("");
        }

        return isValid;
    };

    const handleDonate = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsLoading(true);

            const selected_team = JSON.parse(selectedTeam);
            const requestPayload = {
                member_id: userInfo?.id,
                team_id: selected_team?.team_id,
                member_account_number: userInfo?.account_number,
                team_account_number: selected_team?.account_number,
                donated_amount: amount,
            };

            try {
                const { result, success, message } = await apiService("POST", "/team-donate-runs", requestPayload);

                if (success) {
                    setModal({ confirm: true });
                    setToastMessage({ message: "Donation successful!", toastType: "success" });
                    setTimeout(() => {
                        navigate("/wallets")
                    }, 2000);
                } else {
                    setToastMessage({ message, toastType: "error" });
                }
            } catch (error) {
                setToastMessage({ message: "Error donating runs", toastType: "error" });
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleClose = () => {
        setToastMessage(null);
    };

    return (
        <React.Fragment>
            <Content>
                <BlockHead>
                    <BlockTitle tag="h3" className="text-center">
                        Donate Runs to Your Favorite Team
                    </BlockTitle>
                </BlockHead>
                <Form className="form-validate" onSubmit={handleDonate}>
                    <div className="form-group">
                        <Label for="teamSelect">Select Team</Label>
                        <Input
                            type="select"
                            id="teamSelect"
                            value={selectedTeam}
                            onChange={(e) => setSelectedTeam(e.target.value)}
                            invalid={!!teamError}
                        >
                            <option value="">Select a team</option>
                            {teams.map((team) => (
                                <option key={team.id} value={JSON.stringify(team)}>
                                    {team?.team_name}
                                </option>
                            ))}
                        </Input>
                        <FormFeedback>{teamError}</FormFeedback>
                    </div>

                    <div className="form-group">
                        <Label for="amountInput">Enter Donation Amount (Runs)</Label>
                        <Input
                            type="text"
                            id="amountInput"
                            value={amount}
                            disabled // Disable input so user cannot change the fractional amount
                            invalid={!!amountError}
                        />
                        <FormFeedback>{amountError}</FormFeedback>
                    </div>

                    <Button
                        type="submit"
                        color="primary"
                        className="btn-lg btn-block"
                        disabled={isLoading || parseFloat(amount) === 0} // Disable if no fractional runs
                    >
                        {isLoading ? <Spinner size="sm" /> : "Donate Runs"}
                    </Button>
                </Form>

                <Modal isOpen={modal.confirm} className="modal-dialog-centered">
                    <ModalBody className="text-center">
                        <Icon name="check" className="nk-modal-icon icon-circle bg-success" />
                        <h4 className="nk-modal-title">Donation Successful!</h4>
                        <p>You have successfully donated {amount} runs to the selected team.</p>
                        <Button color="primary" onClick={() => setModal({ confirm: false })}>
                            OK
                        </Button>
                    </ModalBody>
                </Modal>

                <CustomToast
                    open={toastMessage?.toastType ? true : false}
                    handleClose={handleClose}
                    severity={toastMessage?.toastType}
                    message={toastMessage?.message}
                />
            </Content>
        </React.Fragment>
    );
};

export default DonateFractionRuns;
