import React, { useEffect, useState } from 'react';
import { Alert, Button, Container, Form, Spinner, FormGroup, Input, Label, Row, Col, FormFeedback } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import useApiService from '../../../hooks/apiService';
import CustomToast from '../../../components/toast';
import { UseAccountLockStatus } from '../../../hooks/lock-account-status';

const DonateTeamRuns = () => {
    const { apiService, toastMessage, setToastMessage } = useApiService();
    const { accountLockStatus } = UseAccountLockStatus()
    const { walletInfo, userInfo, accountLockstatus } = useSelector((state) => state.menu)

    const location = useLocation();
    const navigate = useNavigate();
    const [teamDetails] = useState(location?.state)
    const [runs, setRuns] = useState((((teamDetails?.lower_limit - teamDetails.team_wallet) / teamDetails?.team_total_shares) * teamDetails?.total_shares)?.toFixed(2));
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState("")
    const [isDonateLoading, setIsDonateLoading] = useState(false);

    console.log("location===", location)


    const handleClose = () => {
        setToastMessage(null);
    };

    // Handle input validation and submission
    const handleDonateClick = async () => {

        await accountLockStatus();

        // if (!accountLockstatus) {
            if (!runs || isNaN(runs) || runs <= 0) {
                setIsValid(false);
                setErrorMessage('Please enter a valid number of runs.');
                return;
            }

            if (runs > walletInfo?.wallet) {
                setIsValid(false);
                setErrorMessage('Your wallet not have the enough runs to donate!');
                return;
            }

            try {
                setIsDonateLoading(true);

                const requestPayload = {
                    member_id: userInfo?.id,
                    team_id: teamDetails?.team_id,
                    member_account_number: userInfo?.account_number,
                    team_account_number: teamDetails?.account_number,
                    donated_amount: runs
                }

                console.log("requestPayload====", requestPayload)

                const { result, success, message } = await apiService('POST', "/team-rent-runs", requestPayload);

                console.log("Donated response", result, success);

                if (success) {
                    setTimeout(() => {
                        navigate("/team-shares-holdings")

                    }, 1200)
                    setToastMessage({
                        message: "Runs Rented Successfully!",
                        toastType: "success"
                    });

                } else if (message === "Failed to authenticate") {
                    setToastMessage({
                        message: "Failed to authenticate. Please login again to continue...",
                        toastType: "error"
                    });
                    navigate('/');
                } else {
                    setToastMessage({
                        message: message,
                        toastType: "error"
                    });
                }
            } catch (err) {
                setToastMessage({
                    message: err || 'An error occurred',
                    toastType: "error"
                });
            } finally {
                setIsDonateLoading(false);
            }
        // } else {
        //     setToastMessage({
        //         message: "Your account has been locked, please try again after 24 hours..",
        //         toastType: "error"
        //     })
        // }
    };

    return (
        <Container className="mt-5 p-5 shadow" style={containerStyle}>
            <Row>
                <Col>
                    <Alert color="info" className="text-center font-weight-bold" style={alertStyle}>
                        The Team has Requested Payment of {runs} for shares by date 
                    </Alert>
                </Col>
            </Row>

            <Form className="mt-4">
                <FormGroup>
                    <Label for="runsInput" className="font-weight-bold text-secondary" style={labelStyle}>Enter Runs to Pay Rent</Label>
                    <Input
                        type="number"
                        id="runsInput"
                        value={runs}
                        placeholder="Enter runs"
                        onChange={(e) => {
                            setRuns(e.target.value);
                            setIsValid(true);
                            setErrorMessage("")
                        }}
                        invalid={!isValid}
                        style={inputStyle}
                        readOnly
                    />
                    <FormFeedback>{errorMessage}</FormFeedback>
                </FormGroup>

                <Button
                    color="primary"
                    className="mt-3"
                    size="lg"
                    onClick={handleDonateClick}
                    style={buttonStyle}>
                    {isDonateLoading ? <Spinner /> : "Pay Rent Runs"}
                </Button>
            </Form>
            <CustomToast
                open={toastMessage?.toastType ? true : false}
                handleClose={handleClose}
                severity={toastMessage?.toastType}
                message={toastMessage?.message}
            />

        </Container>
    );
};

// Custom styles to enhance the UI
const containerStyle = {
    backgroundColor: '#f5f5f5',
    borderRadius: '12px',
    maxWidth: '600px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    background: 'linear-gradient(120deg, #ffffff, #f7f7f7)',
};

const alertStyle = {
    backgroundColor: '#e8f4fc',
    color: '#0056b3',
    padding: '15px',
    borderRadius: '8px',
};

const labelStyle = {
    fontSize: '16px',
    marginBottom: '8px',
};

const inputStyle = {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '8px',
    border: '1px solid #ced4da',
};

const buttonStyle = {
    backgroundColor: '#007bff',
    borderColor: '#007bff',
    padding: '12px 20px',
    fontSize: '18px',
    borderRadius: '8px',
    width: '100%',
    transition: 'all 0.3s ease',
    boxShadow: '0 6px 10px rgba(0, 123, 255, 0.2)',
};


export default DonateTeamRuns;
