import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Input,
  Form,
  Spinner,
  Label,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  Button,
  FormGroup,
  FormFeedback
} from "reactstrap";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Block, BlockHead, BlockTitle, Icon } from "../../../components/Component";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useApiService from "../../../hooks/apiService";
import CustomToast from "../../../components/toast";
import { UseAccountLockStatus } from "../../../hooks/lock-account-status";

const BuySell = () => {
  const { apiService, toastMessage, setToastMessage } = useApiService();
  const { userInfo, accountLockstatus } = useSelector((state) => state.menu);
  const { accountLockStatus } = UseAccountLockStatus()

  const [currency, setCurrency] = useState("USD");
  const [icon, setIcon] = useState("sign-btc-alt");
  const [crypto, setCrypto] = useState("Bitcoin (BTC)");
  const [wallet, setWallet] = useState("BTC Wallet");
  const [runs, setRuns] = useState("");
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState("");
  const [isPaymentConfirmationLoading, setIsPaymentConfirmationLoading] = useState(false)
  const [modal, setModal] = useState({
    confirm: false,
    buyCoin: false,
  });

  const handleApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      console.log("detaisssss", details)
      // setToastMessage({
      //   message: "Transaction completed by " + details.payer.name.given_name,
      //   toastType: "success"
      // });
      handleConfirmOrder(details);
    });
  };

  const handleClose = () => {
    setToastMessage(null);
  };


  // Handler for the runs input field
  const handleRunsChange = (e) => {
    const value = e.target.value;
    setRuns(value);

    // Update the amount automatically based on runs input
    if (value) {
      setAmount(value);
    } else {
      setAmount("");
    }

    // Clear any previous error when the user types
    setError("");
  };


  // Validation function to ensure runs input is not empty
  const validateForm = () => {
    if (runs.trim() === "") {
      setError("Please enter the number of runs you want to buy.");
      return false;
    }
    return true;
  };


  // function to close the form modal
  const onFormCancel = () => {
    setModal({ confirm: false, buyCoin: false });
  };


  const handleContinueToBuy =async (e) => {
    e.preventDefault();

    await accountLockStatus();
    console.log("accountLockstatus===",accountLockstatus)
    // if (!accountLockstatus) {
      if (validateForm()) {
        setModal({ buyCoin: true })
      }
    // } else {
    //   setToastMessage({
    //     message: "Your account has been locked, please try again after 24 hours..",
    //     toastType: "error"
    //   })
    // }
  }

  const handleStoreTransDetails = async (payment_details) => {
    console.log("payment_details---", payment_details)
    const requestPayload = {
      member_id: userInfo?.id,
      member_account_number: userInfo?.account_number,
      trans_id: payment_details.id,
      payer_name: `${payment_details?.payer?.name?.given_name} ${payment_details?.payer?.name?.surname}`,
      payer_email: payment_details?.payer?.email_address,
      payer_id: payment_details?.payer?.payer_id,
      amount: payment_details?.purchase_units[0]?.amount?.value,
      currency: payment_details?.purchase_units[0]?.amount?.currency_code,
      status: payment_details?.status,
      merchant_id: payment_details?.purchase_units[0]?.payee?.merchant_id,
      capture_id: payment_details?.purchase_units[0]?.payments?.captures[0]?.id,
      createdOn: payment_details?.update_time
    };

    console.log("requestPayload", requestPayload);

    try {
      setIsPaymentConfirmationLoading(true);

      const { result, success, message } = await apiService('POST', '/paypal-transaction-report', requestPayload);

      console.log("validate pin response", result, success, message);

      if (success) {
        setModal({
          buyCoin: false,
          confirm: true
        })
        setToastMessage({
          message: "Requested Successfully",
          toastType: "success"
        });

      } else if (message === "Failed to authenticate") {
        setToastMessage({
          message: "Failed to authenticate. Please login again to continue...",
          toastType: "error"
        });
        navigate('/');
      } else {
        setToastMessage({
          message: message,
          toastType: "error"
        });
      }
    } catch (err) {
      console.log("errrr====", err)
      setToastMessage({
        message: err || 'An error occurred',
        toastType: "error"
      });
    } finally {
      setIsPaymentConfirmationLoading(false);
    }

  }

  const handleConfirmOrder = async (details) => {
    const requestPayload = {
      customer_wallet_id: userInfo?.id,
      amount: amount,
      runs: runs,
      account_number: userInfo?.account_number,
      order_status: 'pending',
      payment_method: 'paypal',
    };

    console.log("handleConfirmOrder", requestPayload);

    try {
      setIsPaymentConfirmationLoading(true);

      const { result, success, message } = await apiService('POST', '/requested-member-wallet-runs', requestPayload);

      console.log("validate pin response", result, success, message);

      if (success) {
        //afer sending the request store transaction details as well
        await handleStoreTransDetails(details)

        // setModal({
        //   buyCoin: false,
        //   confirm: true
        // })
        // setToastMessage({
        //   message: "Requested Successfully",
        //   toastType: "success"
        // });

      } else if (message === "Failed to authenticate") {
        setToastMessage({
          message: "Failed to authenticate. Please login again to continue...",
          toastType: "error"
        });
        navigate('/');
      } else {
        setToastMessage({
          message: message,
          toastType: "error"
        });
      }
    } catch (err) {
      console.log("errror", err)
      setToastMessage({
        message: err || 'An error occurred',
        toastType: "error"
      });
    } finally {
      setIsPaymentConfirmationLoading(false);
    }

  }

  return (
    <React.Fragment>
      <Head title="Crypto Dashboard"></Head>
      <Content>
        <div className="buysell wide-xs m-auto">
          <div className="buysell-nav text-center">
            <ul className="nk-nav nav nav-tabs nav-tabs-s2">
              <li className="nav-item">
                <a className="nav-link active" href="#">
                  Buy Runs
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#">
                  Sell Coin
                </a>
              </li> */}
            </ul>
          </div>
          <div className="buysell-title text-center">
            <h2 className="title">Are you want to buy!</h2>
          </div>
          <div className="buysell-block">
            <Form className="buysell-form">
              {/* <div className="form-group buysell-field">
                <div className="form-label-group">
                  <Label for="buysell-choose-currency" className="form-label">
                    Choose what you want to get
                  </Label>
                </div>
                <Input type="hidden" value="btc" name="bs-currency" id="buysell-choose-currency" />
                <UncontrolledDropdown className="buysell-cc-dropdown">
                  <DropdownToggle
                    className="buysell-cc-choosen dropdown-indicator"
                    tag="a"
                    href="toggle"
                    onClick={(ev) => ev.preventDefault()}
                  >
                    <div className="coin-item coin-btc">
                      <div className="coin-icon">
                        <Icon name={icon} />
                      </div>
                      <div className="coin-info">
                        <span className="coin-name">{crypto}</span>
                        <span className="coin-text">Last Order: Nov 23, 2019</span>
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu dropdown-menu-auto dropdown-menu-mxh">
                    <div className="buysell-cc-list">
                      <DropdownItem
                        onClick={() => [setCrypto("Bitcoin (BTC)"), setIcon("sign-btc-alt")]}
                        className="buysell-cc-item selected"
                      >
                        <Link href="#" to="#" className="buysell-cc-opt" data-currency="btc">
                          <div className="coin-item coin-btc">
                            <div className="coin-icon">
                              <Icon name="sign-btc-alt" />
                            </div>
                            <div className="coin-info">
                              <span className="coin-name">Bitcoin (BTC)</span>
                              <span className="coin-text">Last Order: Nov 23, 2019</span>
                            </div>
                          </div>
                        </Link>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => [setCrypto("Ethereum (ETH)"), setIcon("sign-eth-alt")]}
                        className="buysell-cc-item"
                      >
                        <Link href="#" to="#" className="buysell-cc-opt" data-currency="eth">
                          <div className="coin-item coin-eth">
                            <div className="coin-icon">
                              <Icon name="sign-eth-alt" />
                            </div>
                            <div className="coin-info">
                              <span className="coin-name">Ethereum (ETH)</span>
                              <span className="coin-text">Not order yet!</span>
                            </div>
                          </div>
                        </Link>
                      </DropdownItem>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div> */}
              <div className="form-group buysell-field">
                <div className="form-label-group">
                  <Label className="form-label" for="buysell-amount">
                    Enter Runs that you want to buy
                  </Label>
                </div>
                <div className="form-control-group">
                  <Input
                    type="number"
                    id="runsInput"
                    value={runs}
                    className="form-control form-control-lg form-control-number"
                    onChange={handleRunsChange}
                    invalid={error ? true : false}
                    placeholder="Enter number of runs"
                  />
                </div>
                {error && <FormFeedback>{error}</FormFeedback>}
              </div>

              <div className="form-group buysell-field">
                <div className="form-label-group">
                  <Label className="form-label" for="buysell-amount">
                    Amount
                  </Label>
                </div>
                <div className="form-control-group">
                  <Input
                    type="text"
                    className="form-control form-control-lg form-control-number"
                    id="buysell-amount"
                    name="bs-amount"
                    placeholder="1run = $1"
                    value={amount}
                    readOnly
                  />
                  <div className="form-dropdown">
                    <div className="text">
                      Runs<span>/</span>
                    </div>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="dropdown-indicator-caret"
                        tag="a"
                        href="toggle"
                        onClick={(ev) => ev.preventDefault()}
                        disabled
                      >
                        {currency}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu dropdown-menu-xxs dropdown-menu-right text-center">
                        <ul className="link-list-plain sm text-center">
                          <li onClick={() => setCurrency("USD")}>
                            <DropdownItem tag="a" href="#currency" onClick={(ev) => ev.preventDefault()}>
                              USD
                            </DropdownItem>
                          </li>
                          <li onClick={() => setCurrency("BTC")}>
                            <DropdownItem tag="a" href="#currency" onClick={(ev) => ev.preventDefault()}>
                              BTC
                            </DropdownItem>
                          </li>
                          <li onClick={() => setCurrency("ETH")}>
                            <DropdownItem tag="a" href="#currency" onClick={(ev) => ev.preventDefault()}>
                              ETH
                            </DropdownItem>
                          </li>
                          <li onClick={() => setCurrency("YEN")}>
                            <DropdownItem tag="a" href="#currency" onClick={(ev) => ev.preventDefault()}>
                              YEN
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
                <div className="form-note-group">
                  <span className="buysell-min form-note-alt"></span>
                  <span className="buysell-rate form-note-alt">1 Run = $ 1</span>
                </div>
              </div>

              <div className="buysell-field form-group">
                <div className="form-label-group">
                  <label className="form-label">Payment Method</label>
                </div>
                <div className="form-pm-group">
                  <ul className="buysell-pm-list">
                    <li className="buysell-pm-item">
                      <Input className="buysell-pm-control" type="radio" name="bs-method" id="pm-paypal" />
                      <Label className="buysell-pm-label" for="pm-paypal">
                        <span className="pm-name">PayPal</span>
                        <span className="pm-icon">
                          <Icon name="paypal-alt" />
                        </span>
                      </Label>
                    </li>
                    <li className="buysell-pm-item">
                      <Input className="buysell-pm-control" type="radio" name="bs-method" id="pm-bank" />
                      <Label className="buysell-pm-label" for="pm-bank">
                        <span className="pm-name">Bank Transfer</span>
                        <span className="pm-icon">
                          <Icon name="building-fill" />
                        </span>
                      </Label>
                    </li>
                    <li className="buysell-pm-item">
                      <Input className="buysell-pm-control" type="radio" name="bs-method" id="pm-card" />
                      <Label className="buysell-pm-label" for="pm-card">
                        <span className="pm-name">Credit / Debit Card</span>
                        <span className="pm-icon">
                          <Icon name="cc-alt-fill" />
                        </span>
                      </Label>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="buysell-field form-action">
                <Button
                  color="primary"
                  className="btn btn-lg btn-block btn-primary"
                  onClick={handleContinueToBuy}
                >
                  Continue to Buy
                </Button>
              </div>

              {/* <div className="form-note text-base text-center">
                Note: our transfer fee included,{" "}
                <Link tag="a" to="#" href="#" onClick={(ev) => ev.preventDefault()}>
                  see our fees
                </Link>
                .
              </div> */}
            </Form>
          </div>
        </div>
        <Modal
          isOpen={modal.buyCoin}
          toggle={() => setModal({ buyCoin: false })}
          className="modal-dialog-centered"
          size="md"
        >
          <a
            href="#close"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm" />
          </a>
          <ModalBody className="modal-body-lg">
            <BlockHead className="nk-block-head-xs text-center">
              <BlockTitle tag="h5">Confirm Order</BlockTitle>
              <div className="nk-block-text">
                <div className="caption-text">
                  You are about to get <strong>{runs}</strong> Runs for <strong>{amount}</strong> USD*
                </div>
                <span className="sub-text-sm">Runs rate: 1 Run = 1 USD</span>
              </div>
            </BlockHead>
            <Block>
              <div className="buysell-overview">
                <ul className="buysell-overview-list">
                  <li className="buysell-overview-item">
                    <span className="pm-title">Pay with</span>
                    <span className="pm-currency">
                      <Icon name="paypal-alt" /> <span>PayPal</span>
                    </span>
                  </li>
                  <li className="buysell-overview-item">
                    <span className="pm-title">Total</span>
                    <span className="pm-currency">{amount} USD</span>
                  </li>
                </ul>
              </div>

              {/* PayPal Integration */}
              <PayPalScriptProvider options={{ "client-id": "Ac6OZCunTH-foSoVNty9fW1d129CrsXF7_YQMGJryEVvgMSGcm_SokEXFIgkeyTCpqwu9b_A2SQ4XqgF" }}>
                <PayPalButtons
                  style={{ layout: "vertical" }}
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value: amount.toString(), // The amount you want to charge
                          },
                        },
                      ],
                    });
                  }}
                  onApprove={handleApprove}
                  onError={(err) => {
                    console.error("PayPal Checkout onError", err);
                    setToastMessage({
                      message: err || 'Something went wrong with the payment',
                      toastType: "error"
                    });
                  }}
                />
              </PayPalScriptProvider>
            </Block>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={modal.confirm}
          toggle={() => setModal({ confirm: false })}
          className="modal-dialog-centered"
          size="md"
        >
          <a
            href="#close"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm" />
          </a>
          <ModalBody className="modal-body-lg text-center">
            <div className="nk-modal">
              <Icon name="check" className="nk-modal-icon icon-circle icon-circle-xxl bg-success" />
              <h4 className="nk-modal-title">Successfully sent payment!</h4>
              <div className="nk-modal-text">
                <p className="caption-text">
                  You’ve successfully requested <strong>{runs}</strong> Runs for <strong>{amount}</strong> USD. It will updated to your wallet account once approved by admin.
                </p>
                {/* <p className="sub-text-sm">
                  Learn when you reciveve bitcoin in your wallet.
                  <Link to="#" tag="a">
                    Click here
                  </Link>
                </p> */}
              </div>
              <div className="nk-modal-action-lg">
                <ul className="btn-group gx-4">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/wallets`} className="btn btn-lg btn-mw btn-primary">
                      Return
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
      <CustomToast
        open={toastMessage?.toastType ? true : false}
        handleClose={handleClose}
        severity={toastMessage?.toastType}
        message={toastMessage?.message}
      />
    </React.Fragment>
  );
};

export default BuySell;
