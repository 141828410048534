import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, Badge, DropdownItem } from "reactstrap";
import {
    Button,
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    PaginationComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import useApiService from "../../../hooks/apiService";
import { useSelector } from "react-redux";

const PaypalTransReport = () => {
    const { apiService, toastMessage, setToastMessage } = useApiService();
    const { userInfo } = useSelector((state) => state.menu)
    const [data, setData] = useState([]);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState("asc");
    const [isPaypalReportLoading, setIsPaypalReportLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [sessionExpired, setSessionExpired] = useState(false);

    const fetchPaypalTransReport = async () => {
        try {
            setIsPaypalReportLoading(true); // Show loader when fetching data

            const params = {
                page: currentPage,
                limit: itemPerPage,
                order: sort,
                filterName: onSearchText,
                member_id: userInfo?.id
            };

            console.log("params", params);

            const { result, success, message, total } = await apiService(
                'GET',
                '/fetch-member-paypal-trans-report', // Adjust the endpoint if necessary
                null,
                params
            );

            console.log("get recharge res", total, result)
            if (success) {
                setData(result);
                setTotalRows(total);
            } else if (result?.length === 0) {
                setData([]);
                handleApiError('No Wallet report found...');
            } else if (message === 'Failed to authenticate') {
                handleApiError('Failed to authenticate');
                setSessionExpired(true);
                setData([]);
            } else {
                handleApiError(message);
                setData([]);
            }
        } catch (error) {
            console.error('Failed to fetch matches:', error);
            handleApiError(error);
        } finally {
            setIsPaypalReportLoading(false); // Hide loader after data is fetched or an error occurs
        }
    };

    // Sorting data
    const sortFunc = () => {
        let defaultData = [...data];
        if (sort === "dsc") {
            defaultData.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
        } else if (sort === "asc") {
            defaultData.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
        }
        setData(defaultData);
    };

    // Changing state value when searching name
    useEffect(() => {
        fetchPaypalTransReport(); // Fetch data when component mounts or dependencies change
    }, [currentPage, itemPerPage, sort, onSearchText]);

    // Get current list, pagination
    // const indexOfLastItem = currentPage * itemPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemPerPage;
    // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);

    const handleApiError = (message) => {
        // Handle API errors here
        alert(message);
    };

    return (
        <React.Fragment>
            <Head title="Wallet Request List"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Transaction List</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {totalRows} transactions.</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <ul className="nk-block-tools g-3">
                                <li>
                                    <Button color="primary" className="btn-icon">
                                        <Icon name="plus"></Icon>
                                    </Button>
                                </li>
                            </ul>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Card className="card-bordered card-stretch">
                        <div className="card-inner-group">
                            <div className="card-inner">
                                <div className="card-title-group">
                                    <div className="card-title">
                                        <h5 className="title">All Paypal Transactions</h5>
                                    </div>
                                    <div className="card-tools me-n1">
                                        <ul className="btn-toolbar">
                                            <li>
                                                <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                                                    <Icon name="search"></Icon>
                                                </Button>
                                            </li>
                                            <li className="btn-toolbar-sep"></li>
                                            <li>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                                        <Icon name="setting"></Icon>
                                                    </DropdownToggle>
                                                    <DropdownMenu end>
                                                        <ul className="link-check">
                                                            <li>
                                                                <span>Show</span>
                                                            </li>
                                                            <li className={itemPerPage === 10 ? "active" : ""}>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    href="#dropdownitem"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        setItemPerPage(10);
                                                                    }}
                                                                >
                                                                    10
                                                                </DropdownItem>
                                                            </li>
                                                            <li className={itemPerPage === 15 ? "active" : ""}>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    href="#dropdownitem"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        setItemPerPage(15);
                                                                    }}
                                                                >
                                                                    15
                                                                </DropdownItem>
                                                            </li>
                                                        </ul>
                                                        <ul className="link-check">
                                                            <li>
                                                                <span>Order</span>
                                                            </li>
                                                            <li className={sort === "dsc" ? "active" : ""}>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    href="#dropdownitem"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        setSortState("dsc");
                                                                        sortFunc();
                                                                    }}
                                                                >
                                                                    DESC
                                                                </DropdownItem>
                                                            </li>
                                                            <li className={sort === "asc" ? "active" : ""}>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    href="#dropdownitem"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        setSortState("asc");
                                                                        sortFunc();
                                                                    }}
                                                                >
                                                                    ASC
                                                                </DropdownItem>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                                        <div className="search-content">
                                            <Button
                                                className="search-back btn-icon toggle-search"
                                                onClick={() => {
                                                    setSearchText("");
                                                    toggle();
                                                }}
                                            >
                                                <Icon name="arrow-left"></Icon>
                                            </Button>
                                            <input
                                                type="text"
                                                className="form-control border-transparent form-focus-none"
                                                placeholder="Search by Order Id"
                                                value={onSearchText}
                                                onChange={(e) => setSearchText(e.target.value)}
                                            />
                                            <Button className="search-submit btn-icon">
                                                <Icon name="search"></Icon>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-inner p-0">
                                <div className="table-responsive">
                                    <table className="table table-orders">
                                        <thead>
                                            <tr className="tb-item-head">
                                                <th className="tb-col"><span className="overline-title">Transaction Id</span></th>
                                                <th className="tb-col"><span className="overline-title">Payer Name</span></th>
                                                <th className="tb-col"><span className="overline-title">Payer Email</span></th>
                                                <th className="tb-col"><span className="overline-title">Amount</span></th>
                                                <th className="tb-col"><span className="overline-title">Currency</span></th>
                                                <th className="tb-col"><span className="overline-title">Merchant Id</span></th>
                                                <th className="tb-col"><span className="overline-title">Capture Id</span></th>
                                                <th className="tb-col"><span className="overline-title">Status</span></th>
                                                <th className="tb-col"><span className="overline-title">Created On</span></th>
                                                <th className="tb-col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isPaypalReportLoading ? (
                                                <tr>
                                                    <td colSpan="5" className="text-center">Loading...</td>
                                                </tr>
                                            ) : (
                                                data?.length > 0 ?
                                                    data.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="tb-col">{item?.trans_id}</td>
                                                            <td className="tb-col">{item?.payer_name}</td>
                                                            <td className="tb-col">{item?.payer_email}</td>
                                                            <td className="tb-col">$ {item?.amount}</td>
                                                            <td className="tb-col">{item?.currency}</td>
                                                            <td className="tb-col">{item?.merchant_id}</td>
                                                            <td className="tb-col">{item?.capture_id}</td>
                                                            <td className="tb-col">
                                                                <Badge color={item.status === 'COMPLETED' ? 'success' : item.status === 'PENDING' ? 'warning' : 'danger'}>
                                                                    {item?.status?.toUpperCase()}
                                                                </Badge>
                                                            </td>
                                                            <td className="tb-col">{item?.createdOn}</td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <tr>
                                                        <td colSpan="5" className="text-center">No Record Found...</td>
                                                    </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {data.length > 0 && (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={totalRows}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                                 )}
                            </div>
                        </div>
                    </Card>
                </Block>
            </Content>
        </React.Fragment>
    );
};

export default PaypalTransReport;
