import React, { useState, useEffect } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useApiService from "../../hooks/apiService";
import axios from "axios";
import { baseUrl } from "../../config/config";
import CustomToast from "../../components/toast";

const Login = () => {
  const { apiService, toastMessage, setToastMessage } = useApiService();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [isPinScrenDisp, setIsPinScreenDisp] = useState(false);

  // Function to check if the pin is already generated for the user
  const checkPinVerified = async () => {
    try {
      const response = await axios.get(`${baseUrl}/check-pin-verified/${id}`);
      console.log("ressssss",response)
      if (response?.data?.accountExists) {
        setIsPinScreenDisp(true); // Pin is already generated, proceed to validation screen
        navigate("/validate-pin", {state: {id} })
      } else {
        setIsPinScreenDisp(false); // Show pin generation screen
        navigate("/generate-pin",  {state: {id} })
      }
    } catch (err) {
      setToastMessage({
        message: "Error checking pin verification status",
        toastType: "error",
      });
    }
  };

  // Fetch the pin verification status on mount
  useEffect(() => {
    if (id) {
      checkPinVerified();
    }
  }, [id]);

  // Function to validate the user's pin
  // const validatePin = async () => {
  //   const requestPayload = {
  //     pin: inputPin,
  //     id: id
  //     // email: 'rohit_1@gmail.com',
  //   };

  //   try {
  //     setIsValidatePinLoading(true);
  //     const response = await axios.post(`${baseUrl}/validate-pin`, requestPayload);

  //     if (response?.data?.success) {
  //       setToastMessage({
  //         message: "Pin Validate Successfully",
  //         toastType: "success",
  //       });

  //       dispatch(login(response?.data?.token));
  //       dispatch(userDetails(response?.data?.user));
  //       dispatch(walletDetails(response?.data?.wallet));
  //       setTimeout(() => {
  //         window.history.pushState(
  //           `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/dashboard"}`,
  //           "auth-login",
  //           `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/dashboard"}`
  //         );
  //         window.location.reload();
  //       }, 1000);
  //     } else {
  //       setToastMessage({
  //         message: response?.data?.message,
  //         toastType: "error",
  //       });
  //     }
  //   } catch (err) {
  //     setToastMessage({
  //       message: err?.response?.data?.message || 'An error occurred',
  //       toastType: "error",
  //     });
  //   } finally {
  //     setIsValidatePinLoading(false);
  //   }
  // };

  // // Function to generate pin for the user
  // const generatePin = async () => {
  //   // Check if both pins match and are not empty
  //   console.log("inputPin",inputPin,reenterPin, id, !id)

  //   if(!id){
  //     setPinError("Not Getting Users.. Try again later");
  //     return;
  //   }


  //   if (!inputPin || !reenterPin) {
  //     setPinError("Both pin fields are required");
  //     return;
  //   }
  //   if (inputPin !== reenterPin) {
  //     setPinError("Pins do not match");
  //     return;
  //   }

  //   const requestPayload = {
  //     pin: inputPin,
  //     id: id,
  //   };

  //   try {
  //     setLoading(true);
  //     const response = await axios.post(`${baseUrl}/pin-generation`, requestPayload);
  //     console.log("pin generation",response)
  //     if (response?.data?.success) {
  //       setToastMessage({
  //         message: "Pin Generated Successfully",
  //         toastType: "success",
  //       });
  //       setIsPinScreenDisp(true); // Move to pin validation screen
  //     } else {
  //       setToastMessage({
  //         message: response?.data?.message,
  //         toastType: "error",
  //       });
  //     }
  //   } catch (err) {
  //     setToastMessage({
  //       message: err?.response?.data?.message || 'An error occurred',
  //       toastType: "error",
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleClose = () => {
    setToastMessage(null);
  };

  // const onFormSubmit = async (formData) => {
  //   setLoading(true);
  //   const loginName = "rohit_1@gmail.com";
  //   const pass = "Test1234";
  //   if (formData.name === loginName && formData.passcode === pass) {
  //     setIsPinScreenDisp(true);
  //   } else {
  //     setTimeout(() => {
  //       setError("Cannot login with credentials");
  //       setLoading(false);
  //     }, 1000);
  //   }
  // };

  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <>
      <Head title="Login" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>

        {/* {isPinScrenDisp ? (
          <>
            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h4">Enter Pin</BlockTitle>
                  <BlockDes>
                    <p>Access Dashlite using your Pin.</p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>

              <Form className="is-alter" onSubmit={(e) => { e.preventDefault(); validatePin(); }}>
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="pin">Enter Pin</label>
                  </div>
                  <div className="form-control-wrap">
                    <input
                      type="password"
                      id="pin"
                      value={inputPin}
                      onChange={(e) => setInputPin(e.target.value)}
                      placeholder="Enter your pin"
                      className="form-control-lg form-control"
                      required
                    />
                  </div>
                </div>

                <div className="form-group">
                  <Button size="lg" className="btn-block" type="submit" color="primary">
                    {isValidatePinLoading ? <Spinner size="sm" color="light" /> : "Go"}
                  </Button>
                </div>
              </Form>
            </PreviewCard>
          </>
        ) : (
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Set Your Pin</BlockTitle>
                <BlockDes>
                  <p>Access Runsinc using your pin.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>

            {pinError && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  <Icon name="alert-circle" /> {pinError}
                </Alert>
              </div>
            )}

            <Form className="is-alter" onSubmit={(e) => { e.preventDefault(); generatePin(); }}>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="inputPin">Enter Pin</label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="password"
                    id="inputPin"
                    value={inputPin}
                    onChange={(e) => setInputPin(e.target.value)}
                    placeholder="Enter your pin"
                    className="form-control-lg form-control"
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="reenterPin">Re-Enter Pin</label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="password"
                    id="reenterPin"
                    value={reenterPin}
                    onChange={(e) => setReenterPin(e.target.value)}
                    placeholder="Re-enter your pin"
                    className="form-control-lg form-control"
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <Button size="lg" className="btn-block" type="submit" color="primary">
                  {loading ? <Spinner size="sm" color="light" /> : "Set Pin"}
                </Button>
              </div>
            </Form>
          </PreviewCard>
        )} */}
      </Block>
      <AuthFooter />
      <CustomToast
        open={toastMessage?.toastType ? true : false}
        handleClose={handleClose}
        severity={toastMessage?.toastType}
        message={toastMessage?.message}
      />
    </>
  );
};

export default Login;
