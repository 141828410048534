const menu = [
  
  
  { heading: "Dashboard" },
  {
    icon: "bitcoin-cash",
    text: "Dashboard",
    link: "/dashboard",
    id: "common"
  },
  {
    icon: "sign-btc-alt",
    text: "Wallets",
    icon: "wallet-alt",
    link: "/wallets",
    id: "common"
  },
  {
    icon: "sign-btc-alt",
    text: "Buy Runs",
    icon: "coins",
    link: "/buy-sell",
    id: "Player"
  },
  {
    icon: "sign-btc-alt",
    text: "Auction",
    icon: "repeat",
    link: "/team-auction-list",
    id: "Player"
  },
  {
    icon: "sign-btc-alt",
    text: "IPO",
    icon: "repeat",
    link: "/team-ipo-list",
    id: "Player"
  },
  {
    icon: "sign-btc-alt",
    text: "My Holdings",
    icon: "repeat",
    link: "/team-shares-holdings",
    id: "Player"
  },

  {
    icon: "tranx",
    text: "Transaction Report",
    link: "/transaction-basic",
    id: 'common'
    // active: false,
    // subMenu: [
    //   {
    //     text: "Trans List - Basic",
    //     link: "/transaction-basic",
    //   },
    //   {
    //     text: "Trans List - Crypto",
    //     link: "/transaction-crypto",
    //   },
    // ],
  },
  {
    icon: "file-docs",
    text: "Reports",
    active: false,
    id: 'Player',
    subMenu: [
      {
        icon: "file-docs",
        text: "Order History",
        link: "/team-shares-holdings-transaction",
      },
      {
        icon: "file-docs",
        text: "Payment Report",
        link: "/paypal-report-list",
      },
      {
        icon: "file-docs",
        text : "Run Report",
        link : "customer-report-list"
      },
      {
        icon: "file-docs",
        text: "Donation Report",
        link: "/donation-report",
      },
      {
        icon: "file-docs",
        text: "Rent Report",
        link: "/rent-report",
      },
    ],
  },
  {
    icon: "file-docs",
    text: "Boardroom",
    active: false,
    id: "Player",
    subMenu: [
      {
        icon: "sign-btc-alt",
        text: "Board Room",
        icon: "repeat",
        link: "/team-shares-board-room",
      },
      {
        icon: "sign-btc-alt",
        text: "Chats",
        icon: "chat-circle",
        link: "/chat",
      },
      {
        icon: "sign-btc-alt",
        text: "Boardroom  Meetings",
        icon: "clock",
        link: "/app-calender",
      },
    ],
  },
  {
    icon: "sign-btc-alt",
    text: "Open Orders",
    icon: "repeat",
    link: "/order-history",
    id: "Player"
  },
  {
    icon: "sign-btc-alt",
    text: "Favorite Team",
    icon: "coins",
    link: "/favorite-team-list",
    id: "Player"
  },
  {
    icon: "sign-btc-alt",
    text: "Board Room",
    icon: "repeat",
    link: "/team-shares-board-room",
    id: "Team"
  },
  {
    icon: "sign-btc-alt",
    text: "Chats",
    icon: "chat-circle",
    link: "/chat",
     id: "Team"
  },
  {
    icon: "sign-btc-alt",
    text: "Boardroom  Meetings",
    icon: "clock",
    link: "/app-calender",
     id: "Team"
  },
  {
   icon: "tranx",
    text: "Rent Report",
    icon: "chat-circle",
    link: "/team-rent-report",
     id: "Team"
  },
  {
    icon: "tranx",
    text: "Donate Run",
    icon: "donate-run",
    link: "/donate-run",
    id: "Team"
  },

  {
    icon: "file-docs",
    text: "AML / KYCs",
    active: false,
    subMenu: [
      {
        text: "KYC List - Regular",
        link: "/kyc-list-regular",
      },
      {
        text: "KYC Details - Regular",
        link: "/kyc-details-regular/UD01544",
      },
    ],
  },
  // {
  //   icon: "file-docs",
  //   text: "Invoice",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Invoice List",
  //       link: "/invoice-list",
  //     },
  //     {
  //       text: "Invoice Details",
  //       link: "/invoice-details/1",
  //     },
  //   ],
  // } 
];
export default menu;
