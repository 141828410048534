import React, { useEffect, useState, useContext } from "react";
import Head from "../../../../layout/head/Head";
import ContentAlt from "../../../../layout/content/ContentAlt";
import AppContact from "./contact/Contact";
import ChatBody from "./ChatBody";
import User from "../../../../images/avatar/b-sm.jpg";
import {
  Button, Icon, UserAvatar, DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem, PaginationComponent
} from "../../../../components/Component";
import {
  DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem, Modal,
  ModalBody, Spinner
} from "reactstrap";
import { chatData } from "./ChatData";
import { ChatContext } from "./ChatContext";
import { Link, useNavigate } from "react-router-dom";
import { ChannelAsideBody, ChatAsideBody } from "./ChatAsideBody";
import Content from "../../../../layout/content/Content";
import io from "socket.io-client";
import useApiService from "../../../../hooks/apiService";
import CustomToast from "../../../../components/toast";
import { useSelector } from "react-redux";

const Chat = () => {
  const { apiService, toastMessage, setToastMessage } = useApiService();
  const navigate = useNavigate()
  const { userInfo } = useSelector((state) => state.menu)
  const [mainTab, setMainTab] = useState("Chats");
  const [selectedId, setSelectedId] = useState(1);
  const [filterTab, setFilterTab] = useState("messages");
  // const [filteredChatList, setFilteredChatList] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [favState, setFavState] = useState(false);
  const [favFilter, setFavFilter] = useState([]);
  const [favFilterText, setFavFilterText] = useState("");
  const [mobileView, setMobileView] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [itemPerPage] = useState(10);
  const [groupCurrentPage, setGroupCurrentPage] = useState(1); // For pagination
  const [groupTotalRows, setGroupTotalRows] = useState(0); // Total rows for pagination
  const [groupItemPerPage] = useState(10); // Items per page (You can adjust the limit)
  const [isTeamMembersLoading, setIsTeamMembersLoading] = useState(false); // Loading state
  const [isChatListLoading, setIsChatListLoading] = useState(false);
  const [groupMember, setGroupMembers] = useState([])
  const [chatList, setChatList] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedGroupMembers, setSelectedGroupMembers] = useState([])
  const { chatState, fav } = useContext(ChatContext);

  const [chat, setChat] = chatState;
  const [favData] = fav;

  // Filtering users by search
  // useEffect(() => {
  //   if (filterText !== "") {
  //     const filteredObject = chatData.filter((item) => {
  //       return item.name.toLowerCase().includes(filterText.toLowerCase());
  //     });
  //     setFilteredChatList([...filteredObject]);
  //   } else {
  //     setFilteredChatList([...chatData]);
  //   }
  // }, [filterText, setFilteredChatList]);

  // Filtering favourite users by search
  useEffect(() => {
    if (favFilterText !== "") {
      const filteredObject = favData.filter((item) => {
        return item.name.toLowerCase().includes(favFilterText.toLowerCase()) && item.fav === false;
      });
      setFavFilter([...filteredObject]);
    } else {
      setFavFilter([]);
    }
  }, [favFilterText, favData]);



  useEffect(() => {
    fetchChatList(false);
    fetchTeamMembersList();
  }, [])

  const handleCheckboxChange = (event, itemId) => {
    if (event.target.checked) {
      // Add the item ID to the state if checked
      setSelectedGroupMembers([...selectedGroupMembers, itemId]);
    } else {
      // Remove the item ID from the state if unchecked
      setSelectedGroupMembers(selectedGroupMembers.filter(id => id !== itemId));
    }
  };

  const paginate = (pageNumber) => setGroupCurrentPage(pageNumber);


  const fetchTeamMembersList = async () => {
    try {
      setIsTeamMembersLoading(true); // Show loader when fetching data
      const params = {
        page: groupCurrentPage,
        limit: groupItemPerPage,
        order: "asc", // Set sorting if required
        // filterName: filterText,
        team_id: userInfo?.id,
      };

      const { result, success, message, total } = await apiService(
        "GET",
        "/fetch-team-board-room-list", // Adjust the endpoint if necessary
        null,
        params
      );

      console.log("Team Members lists=====", result)

      if (success) {
        setGroupMembers(result);
        setGroupTotalRows(total); // Set total rows for pagination
      } else if (result?.length === 0) {
        handleApiError("No Wallet report found...");
      } else if (message === "Failed to authenticate") {
        handleApiError("Failed to authenticate");
        setSessionExpired(true);
      } else {
        handleApiError(message);
      }
    } catch (error) {
      console.error("Failed to fetch matches:", error);
      handleApiError(error);
    } finally {
      setIsTeamMembersLoading(false); // Hide loader after data is fetched or an error occurs
    }
  };

  const createGroup = async () => {
    console.log("selectedGroupMembers",selectedGroupMembers)
    const chatObj = {
      is_group: true,
      message_text: "",
      member_id: userInfo?.id,
      group_member_id: selectedGroupMembers
    }

    try {
      // setIsStartChatLoading(true);
      const { result, success, message } = await apiService('POST', '/create-group', chatObj);

      console.log("create chat response", result, success, message);

      if (success) {
        setToastMessage({
          message: "Group created successfully",
          toastType: "success"
        });
        fetchChatList(true)
      } else if (message === "Failed to authenticate") {
        setToastMessage({
          message: "Failed to authenticate. Please login again to continue...",
          toastType: "error"
        });
        navigate('/');
      } else {
        setToastMessage({
          message: message,
          toastType: "error"
        });
      }
    } catch (err) {
      console.log("errrr====", err)
      setToastMessage({
        message: err || 'An error occurred',
        toastType: "error"
      });
    } finally {
      // setIsStartChatLoading(false);
    }
  }


  const handleApiError = (message) => {
    // Handle API errors here
    setToastMessage({
      message: message,
      toastType: "error"
    });
  };

  const onInputChange = (e) => {
    setFilterText(e.target.value);
  };

  const favInputSearchChange = (e) => {
    setFavFilterText(e.target.value);
  };

  const onFilterClick = (prop) => {
    setFilterTab(prop);
    if(prop == 'group'){
      fetchChatList(true)
    }
  };

  const chatItemClick = (id) => {
    // let data = chat;
    // const index = data.findIndex((item) => item.id === id);
    // const dataSet = data.find((item) => item.id === id);
    // if (dataSet.unread === true) {
    //   data[index].unread = false;
    //   console.log("chatItemClick",[...data])
    //   setChat([...data]);
    // }
    setSelectedId(id);
    if (window.innerWidth < 860) {
      setMobileView(true);
    }
  };

  const handleClose = () => {
    setToastMessage(null);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalRows / itemPerPage);
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const fetchChatList = async (group_status) => {
    try {
      setIsChatListLoading(true); // Show loader when fetching data

      const params = {
        page: currentPage,
        limit: itemPerPage,
        order: 'desc',
        member_id: userInfo?.id,
        is_group: group_status
      };

      console.log("params", params);

      const { result, success, message, total } = await apiService(
        'GET',
        '/fetch-chat-list',
        null,
        params
      );

      console.log("get recharge res", total, result)
      if (success) {
        setChatList(result);
        setTotalRows(total);
      } else if (result?.length === 0) {
        handleApiError('No Wallet report found...');
      } else if (message === 'Failed to authenticate') {
        handleApiError('Failed to authenticate');
        setSessionExpired(true);
      } else {
        handleApiError(message);
      }
    } catch (error) {
      console.error('Failed to fetch matches:', error);
      handleApiError(error);
    } finally {
      setIsChatListLoading(false); // Hide loader after data is fetched or an error occurs
    }
  };


  return (
    <React.Fragment>
      <Head title="Chat / Messenger"></Head>
      <ContentAlt>
        <div className="nk-chat">
          <div className={`nk-chat-aside ${mobileView ? "has-aside" : ""}`}>
            <div className="nk-chat-aside-head">
              <div className="nk-chat-aside-user">
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="dropdown-toggle dropdown-indicator">
                    <UserAvatar image={User}></UserAvatar>
                    <div className="title">{mainTab}</div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#contact"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setMainTab(mainTab === "Chats" ? "Contact" : "Chats");
                          }}
                        >
                          <span>{mainTab === "Chats" ? "Contact" : "Chats"}</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#contact"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setMainTab(mainTab === "Chats" ? "Channel" : mainTab === "Channel" ? "Contact" : "Channel");
                          }}
                        >
                          <span>{mainTab === "Chats" ? "Channel" : mainTab === "Channel" ? "Contact" : "Channel"}</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <ul className="nk-chat-aside-tools g-2">
                {mainTab === "Chats" || mainTab === "Channel" ? (
                  <React.Fragment>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="btn btn-round btn-icon btn-light dropdown-toggle">
                          <Icon name="setting-alt-fill"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdown"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <span>Settings</span>
                              </DropdownItem>
                            </li>
                            <li className="divider"></li>
                            <li
                              onClick={() => onFilterClick("messages")}
                              className={filterTab === "messages" ? "active" : ""}
                            >
                              <DropdownItem
                                tag="a"
                                href="#dropdown"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <span>Messages</span>
                              </DropdownItem>
                            </li>
                            {/* <li
                              onClick={() => onFilterClick("archive")}
                              className={filterTab === "archive" ? "active" : ""}
                            >
                              <DropdownItem
                                tag="a"
                                href="#dropdown"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <span>Archives Chats</span>
                              </DropdownItem>
                            </li>
                            <li
                              onClick={() => onFilterClick("unread")}
                              className={filterTab === "unread" ? "active" : ""}
                            >
                              <DropdownItem
                                tag="a"
                                href="#dropdown"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <span>Unread Chats</span>
                              </DropdownItem>
                            </li> */}
                            <li
                              onClick={() => onFilterClick("group")}
                              className={filterTab === "group" ? "active" : ""}
                            >
                              <DropdownItem
                                tag="a"
                                href="#dropdown"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <span>Group Chats</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="btn btn-round btn-icon btn-light dropdown-toggle">
                          <Icon name="edit-alt-fill"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li
                              onClick={() => onFilterClick("messages")}
                            >
                              <DropdownItem
                                tag="a"
                                href="#dropdown"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setModal(true)
                                }}
                              >
                                <span>Create Group</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </React.Fragment>
                ) : (
                  <li>
                    <Button color="light" className="btn-round btn-icon">
                      <Icon name="user-add-fill"></Icon>
                    </Button>
                  </li>
                )}
              </ul>
            </div>
            {mainTab === "Chats" ? (
              <ChatAsideBody
                onInputChange={onInputChange}
                chatList={chatList}
                favState={favState}
                favFilter={favFilter}
                setFavFilter={setFavFilter}
                setFavState={setFavState}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                favInputSearchChange={favInputSearchChange}
                favFilterText={favFilterText}
                chatItemClick={chatItemClick}
                filterTab={filterTab}
                isChatListLoading={isChatListLoading}
              />
            ) : mainTab === "Channel" ? (
              <ChannelAsideBody
                chatList={chatList}
                onInputChange={onInputChange}
                setSelectedId={setSelectedId}
                setMobileView={setMobileView}
                selectedId={selectedId}
                chatItemClick={chatItemClick}
                filterTab={filterTab}
                isChatListLoading={isChatListLoading}
              />
            ) : (
              <AppContact setTab={setMainTab} setSelectedId={setSelectedId} fetchChatList={fetchChatList} />
            )}
          </div>
          {selectedId !== null ? (
            <ChatBody
              id={selectedId}
              setSelectedId={setSelectedId}
              setMobileView={setMobileView}
              mobileView={mobileView}
              fetchChatList={fetchChatList}
            />
          ) : (
            <div className="nk-chat-body">
              <div className="nk-chat-blank">
                <div className="nk-chat-blank-icon">
                  <Icon name="chat" className="icon-circle icon-circle-xxl bg-white"></Icon>
                </div>
                <div className="nk-chat-blank-btn">
                  <Link to={`${process.env.PUBLIC_URL}/app-chat`}>
                    <Button color="primary" disabled={mainTab === "Contact"} onClick={() => setMainTab("Contact")}>
                      Start Chat
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </ContentAlt>

      <Modal
        isOpen={modal}
        toggle={() => setModal(false)}
        className="modal-dialog-centered"
        size="md"
      >
        <a
          href="#close"
          onClick={(ev) => {
            ev.preventDefault();
            setModal(false)
          }}
          className="close"
        >
          <Icon name="cross-sm" />
        </a>
        <ModalBody className="modal-body-lg text-center">
          <div className="nk-modal">
            <h4 className="nk-modal-title">Select Members</h4>
            <div className="nk-modal-text">
              <DataTableBody bodyclass="nk-tb-tnx">
                <DataTableHead>
                  <DataTableRow>
                    <span>Select</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span>Members Name</span>
                  </DataTableRow>
                </DataTableHead>

                {isTeamMembersLoading ? (
                  <tr>
                    <td colSpan="5" className="text-center">
                      <Spinner color="primary" type="grow" />
                    </td>
                  </tr>
                ) : groupMember.length > 0 ? (
                  groupMember.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow>
                          {/* Checkbox to select the row */}
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, item?.member_id)}
                            checked={selectedGroupMembers.includes(item?.member_id)}
                          />
                        </DataTableRow>
                        <DataTableRow>
                          <div className="nk-tnx-type">
                            <div className="nk-tnx-type-icon bg-success-dim text-success">
                              <Icon name="arrow-up-right"></Icon>
                            </div>
                            <div className="nk-tnx-type-text">
                              <span className="tb-lead">{item?.name} {item?.mname} {item?.lname}</span>
                            </div>
                          </div>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center mt-5">No Record Found!</td>
                  </tr>
                )}

              </DataTableBody>
              <div className="card-inner">
                {groupMember.length > 0 && (
                  <PaginationComponent
                    itemPerPage={itemPerPage}
                    totalItems={totalRows}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                )}
              </div>
            </div>
            <div className="nk-modal-action-lg">
              <ul className="btn-group gx-4">
                <li>
                  <Link className="btn btn-lg btn-mw btn-primary" onClick={createGroup}>
                    Create Group
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <CustomToast
        open={toastMessage?.toastType ? true : false}
        handleClose={handleClose}
        severity={toastMessage?.toastType}
        message={toastMessage?.message}
      />
    </React.Fragment>
  );
};

export default Chat;
