import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useNavigate } from "react-router";
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,

    ModalBody,
    Modal,
    DropdownItem,
    Form,
    Badge,
    Spinner
} from "reactstrap";
import {
    Button,
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    PaginationComponent,
} from "../../../components/Component";
import useApiService from "../../../hooks/apiService";
import CustomToast from "../../../components/toast";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const AddFavoriteTeam = () => {
    const { apiService, toastMessage, setToastMessage } = useApiService();
    const { userInfo } = useSelector((state)=>state.menu)
    const location = useLocation();
    const navigate = useNavigate();
    
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState("");
    const [isFavoriteTeamListLoading, setIsFavoriteTeamListLoading] = useState(false)
    const [selectedTeam, setSelectedTeam] = useState("");
    const [isAddFavoriteTeamLoading, setIsAddFavoriteTeamLoading] = useState(false)
    const [isAddFavoriteModalOpen, setIsAddFavoriteModalOpen] = useState(false)
    // Sorting data
    const sortFunc = (params) => {
        let defaultData = data;
        if (params === "asc") {
            let sortedData = defaultData.sort((a, b) => a.ref.localeCompare(b.ref));
            setData([...sortedData]);
        } else if (params === "dsc") {
            let sortedData = defaultData.sort((a, b) => b.ref.localeCompare(a.ref));
            setData([...sortedData]);
        }
    };

    // Changing state value when searching name
    useEffect(() => {
        if (onSearchText !== "") {
            const filteredObject = data.filter((item) => {
                return item?.ref.toLowerCase().includes(onSearchText.toLowerCase());
            });
            setData([...filteredObject]);
        } else {
            setData([...data]);
        }
    }, [onSearchText]);

    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleClose = () => {
        setToastMessage(null);
    };


    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);

    // function to toggle details modal
    const handleApiError = (err) => {
        setToastMessage({
            message: err,
            toastType: "error"
        });
    }

    const fetchFavoriteTeamList = async () => {
        try {
            setIsFavoriteTeamListLoading(true); // Show loader when fetching data

            const params = {
                page: currentPage,
                limit: itemPerPage,
                order: sort,
                filterName: onSearchText,
                auction_status: true,
                ipo_status: 'soldout'
            };

            console.log("params", params);

            const { result, success, message, total } = await apiService(
                'GET',
                '/fetch-team-share', // Adjust the endpoint if necessary
                null,
                params
            );

            console.log("get recharge res", total, result)
            if (success) {
                setData(result);
                setTotalRows(total);
            } else if (result?.length === 0) {
                setData([]);
                handleApiError('No Wallet report found...');
            } else if (message === 'Failed to authenticate') {
                handleApiError('Failed to authenticate');
                setSessionExpired(true);
                setData([]);
            } else {
                handleApiError(message);
                setData([]);
            }
        } catch (error) {
            console.error('Failed to fetch matches:', error);
            handleApiError(error);
        } finally {
            setIsFavoriteTeamListLoading(false); // Hide loader after data is fetched or an error occurs
        }
    }

    const handleConfirmFavoriteTeam = async (e) => {
        console.log("handleConfirmFavoriteTeam")
        const requestPayload = {
            team_id: selectedTeam?.team_id,
            member_id: userInfo?.id,
        };
        console.log("requestPayload", requestPayload);

        try {
            setIsAddFavoriteTeamLoading(true);

            const { result, success, message } = await apiService('POST', '/add-favorite-team', requestPayload);

            console.log("favorite response", result, success, message);

            if (success) {
                setIsAddFavoriteModalOpen(false);
                navigate("/favorite-team-list")
                setToastMessage({
                    message: "Team added to favorite Successfully!",
                    toastType: "success"
                });

            } else if (message === "Failed to authenticate") {
                setToastMessage({
                    message: "Failed to authenticate. Please login again to continue...",
                    toastType: "error"
                });
                navigate('/');
            } else {
                setToastMessage({
                    message: message,
                    toastType: "error"
                });
            }
        } catch (err) {
            console.log("errr", err)
            setToastMessage({
                message: err || 'An error occurred',
                toastType: "error"
            });
        } finally {
            setIsAddFavoriteTeamLoading(false);
        }

    }



    // Changing state value when searching name
    useEffect(() => {
        fetchFavoriteTeamList(); // Fetch data when component mounts or dependencies change
    }, [currentPage, itemPerPage, sort, onSearchText]);

    return (
        <React.Fragment>
            <Head title="Trasaction List - Crypto"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>All Team List</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {totalRows} teams.</p>
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <DataTable className="card-stretch">
                        <div className="card-inner">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Teams List</h5>
                                </div>
                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <Button
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </Button>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                                    <Icon name="setting"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu end className="dropdown-menu-xs">
                                                    <ul className="link-check">
                                                        <li>
                                                            <span>Show</span>
                                                        </li>

                                                        <li className={itemPerPage === 10 ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setItemPerPage(10);
                                                                }}
                                                            >
                                                                10
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={itemPerPage === 15 ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setItemPerPage(15);
                                                                }}
                                                            >
                                                                15
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                    <ul className="link-check">
                                                        <li>
                                                            <span>Order</span>
                                                        </li>
                                                        <li className={sort === "dsc" ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setSortState("dsc");
                                                                    sortFunc("dsc");
                                                                }}
                                                            >
                                                                DESC
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={sort === "asc" ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setSortState("asc");
                                                                    sortFunc("asc");
                                                                }}
                                                            >
                                                                ASC
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                                    <div className="search-content">
                                        <Button
                                            onClick={() => {
                                                setSearchText("");
                                                toggle();
                                            }}
                                            className="search-back btn-icon toggle-search"
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Search by Order Id"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTableBody bodyclass="nk-tb-tnx">
                            <DataTableHead>
                                <DataTableRow>
                                    <span>Team Name</span>
                                </DataTableRow>

                                <DataTableRow>
                                    <span>Market Price</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>Shares Value</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>Upper Limit</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>Lower Limit</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>Add Favorite</span>
                                </DataTableRow>

                                <DataTableRow className="nk-tb-col-tools"></DataTableRow>
                            </DataTableHead>

                            {isFavoriteTeamListLoading ? (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        <Spinner
                                            color="primary"
                                            type="grow"
                                        >
                                            Loading...
                                        </Spinner>
                                    </td>
                                </tr>
                            ) : (
                                data.length > 0
                                    ? data.map((item) => {
                                        return (
                                            <DataTableItem key={item?.id}>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead">{`${item?.team_name}`}</span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>

                                                <DataTableRow size="sm">
                                                    <span className="tb-amount">
                                                        {item?.market_price || 0}
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow size="sm">
                                                    <span className="tb-amount">
                                                        {item?.team_wallet || 0}
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow size="sm">
                                                    <span className="tb-amount">
                                                        {item?.upper_limit || 0}
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow size="sm">
                                                    <span className="tb-amount">
                                                        {item?.lower_limit || 0}
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow className="nk-tb-col-tools text-end">
                                                    <Button onClick={() => {
                                                        setSelectedTeam(item);
                                                        setIsAddFavoriteModalOpen(true);
                                                    }} color="primary" size="md">
                                                        Add Favorite
                                                    </Button>
                                                </DataTableRow>
                                            </DataTableItem>
                                        );
                                    })
                                    :
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            <Spinner
                                                color="primary"
                                                type="grow"
                                            >
                                                No Record Found!
                                            </Spinner>
                                        </td>
                                    </tr>)}
                        </DataTableBody>
                        <div className="card-inner">
                            {data.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={totalRows}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) :
                                !isFavoriteTeamListLoading && (
                                    <div className="text-center">
                                        <span className="text-silent">No data found</span>
                                    </div>
                                )}
                        </div>
                    </DataTable>
                </Block>

            </Content>

            <Modal
                isOpen={isAddFavoriteModalOpen}
                toggle={() => setIsAddFavoriteModalOpen(true)}
                className="modal-dialog-centered"
                size="md"
            >
                <a
                    href="#close"
                    onClick={() => setIsAddFavoriteModalOpen(false)}
                    className="close"
                >
                    <Icon name="cross-sm" />
                </a>
                <ModalBody className="modal-body-lg">
                    <BlockHead className="nk-block-head-xs text-center">
                        <BlockTitle tag="h5">Are you sure you want to add this team as favorite team?</BlockTitle>
                        <div className="nk-block-text">
                        </div>
                    </BlockHead>
                    <Block>
                        <div className="buysell-field form-action text-center">
                            <div>
                                <Button
                                    color="primary"
                                    className="btn btn-primary btn-lg"
                                    onClick={handleConfirmFavoriteTeam}
                                >
                                    {isAddFavoriteTeamLoading ? <Spinner size="sm" color="light" /> : "Add"}
                                </Button>
                            </div>
                            <div className="pt-3">
                                <a
                                    href="#"
                                    className="link link-danger"
                                    onClick={() => setIsAddFavoriteModalOpen(false)}
                                >
                                    Cancel
                                </a>
                            </div>
                        </div>
                    </Block>
                </ModalBody>
            </Modal>

            <CustomToast
                open={toastMessage?.toastType ? true : false}
                handleClose={handleClose}
                severity={toastMessage?.toastType}
                message={toastMessage?.message}
            />
        </React.Fragment>
    );
};

export default AddFavoriteTeam;
