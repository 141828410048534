import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
    Input,
    Form,
    Spinner,
    Label,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    Button,
    FormGroup,
    FormFeedback,
    Col,
    Row
} from "reactstrap";
import { Block, BlockHead, BlockTitle, Icon } from "../../../components/Component";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useApiService from "../../../hooks/apiService";
import CustomToast from "../../../components/toast";
import { UseMemberWalletBalanceUpdate } from "../../../hooks/account-balance";
import { UseAccountLockStatus } from "../../../hooks/lock-account-status";

const BuyTeamShare = () => {
    const { apiService, toastMessage, setToastMessage } = useApiService();
    const { updateMemberWalletBalance } = UseMemberWalletBalanceUpdate()
    const { accountLockStatus } = UseAccountLockStatus()

    const { userInfo, walletInfo, accountLockstatus } = useSelector((state) => state.menu);
    const location = useLocation();
    console.log("locationsss", location, accountLockstatus);
    const [teamDetails] = useState(location?.state)
    const total_shares = teamDetails?.ipo_status === 'soldout' ? teamDetails?.team_member_share : teamDetails?.ipo_shares;

    const [teamShares, setTeamShares] = useState("");
    const [runs, setRuns] = useState("");
    const [error, setError] = useState("");
    const [isAddSharesLoading, setIsAddSharesLoading] = useState(false)
    const [modal, setModal] = useState({
        confirm: false,
        buyCoin: false,
    });
    const [buyOption, setBuyOption] = useState("market"); // Add option for market or limit price
    const [limitPrice, setLimitPrice] = useState(""); // For limit input field
    const [limitPriceError, setLimitPriceError] = useState("");

    const handleClose = () => {
        setToastMessage(null);
    };


    // Handler for the runs input field
    const handleSharesChange = (e) => {
        const value = e.target.value;

        // Check if the entered value is greater than the allowed total share sell
        if (value > Number(total_shares)) {
            setError(`The entered value exceeds the maximum allowed shares of ${total_shares}.`);
        } else {
            setTeamShares(value);

            // Update the runs amount automatically based on the entered shares value
            if (value) {
                const price = buyOption === "market" ? teamDetails?.ipo_status === 'soldout' ? teamDetails?.asking_runs : teamDetails?.market_price :  limitPrice;
                setRuns(value * price);
            } else {
                setRuns("");
            }

            // Clear any previous error when the user types a valid value
            setError("");
        }
    };

    // Handle buy option change (market or limit price)
    const handleBuyOptionChange = (e) => {
        setBuyOption(e.target.value);
        if (teamShares) {
            const price = e.target.value === "market" ? teamDetails?.ipo_status === 'soldout' ? teamDetails?.asking_runs : teamDetails?.market_price : limitPrice;
            setRuns(teamShares * price);
        }
    };

    // Handler for limit price input field
    const handleLimitPriceChange = (e) => {
        const value = e.target.value;
        setLimitPrice(value);

        if (teamShares) {
            setRuns(teamShares * value);
        }
        setLimitPriceError('')
    };



    // Validation function to ensure runs input is not empty
    const validateForm = () => {
        if (teamShares.trim() === "") {
            setError("Please enter the number of runs you want to buy.");
            return false;
        }
        if (teamShares > Number(total_shares)) {
            setError(`The entered value exceeds the maximum allowed shares of ${total_shares}.`);
            return false;
        }
        if (buyOption === "limit" && !limitPrice && teamDetails?.ipo_status === 'soldout') {
            setLimitPriceError("Limit Price is required.");
            isValid = false;
        }
        return true;
    };


    // function to close the form modal
    const onFormCancel = () => {
        setModal({ confirm: false, buyCoin: false });
    };


    const handleContinueToBuy = async (e) => {
        e.preventDefault();
        await accountLockStatus();
        console.log("accountLockstatus===", accountLockstatus)


        // if (!accountLockstatus) {
        if (validateForm()) {
            if (walletInfo?.wallet >= runs) {
                setModal({ buyCoin: true })
            } else {
                setToastMessage({
                    message: "Your wallet not have the enough runs to buy. Please add runs to continue..",
                    toastType: "error"
                })
            }
        }
        // } else {
        //     setToastMessage({
        //         message: "Your account has been locked, please try again after 24 hours..",
        //         toastType: "error"
        //     })
        // }

    }

    const checkWalletBalance = () => {
        if (runs <= walletInfo?.wallet) {
            return true
        } else {
            return false;
        }
    }

    const handleConfirmOrder = async (e) => {
        if (checkWalletBalance()) {
            const requestPayload = {
                team_shares: teamShares,
                runs: runs,
                team_id: teamDetails?.team_id,
                member_id: userInfo?.id,
                account_number: userInfo?.account_number,
                team_account_number: teamDetails?.account_number,
                market_price: teamDetails?.market_price,
                limitPrice: limitPrice
            };

            if(teamDetails?.ipo_status === 'soldout'){
                if(buyOption === "limit"){
                    requestPayload.limitPrice = limitPrice
                }else{
                    requestPayload.limitPrice = teamDetails?.asking_runs;
                }
            }

            console.log("requestPayload", requestPayload);

            try {
                setIsAddSharesLoading(true);

                const buy_type_url = teamDetails?.ipo_status === 'soldout' ? '/member-buy-team-auction-shares' : '/member-buy-team-shares';
                const { result, success, message } = await apiService('POST', buy_type_url, requestPayload);

                console.log("validate pin response", result, success);

                if (success) {
                    await updateMemberWalletBalance();
                    setModal({
                        buyCoin: false,
                        confirm: true
                    })
                    setToastMessage({
                        message: "Team Shares Buy Successfully!",
                        toastType: "success"
                    });

                } else if (message === "Failed to authenticate") {
                    setToastMessage({
                        message: "Failed to authenticate. Please login again to continue...",
                        toastType: "error"
                    });
                    navigate('/');
                } else {
                    setToastMessage({
                        message: message,
                        toastType: "error"
                    });
                }
            } catch (err) {
                setToastMessage({
                    message: err || 'An error occurred',
                    toastType: "error"
                });
            } finally {
                setIsAddSharesLoading(false);
            }
        } else {
            handleApiError("Requested runs are more that your wallet balance. Please recharge your wallet and try again.");
        }

    }

    return (
        <React.Fragment>
            <Head title="Crypto Dashboard"></Head>
            <Content>
                <div className="buysell wide-xs m-auto">
                    <div className="buysell-nav text-center">
                        <ul className="nk-nav nav nav-tabs nav-tabs-s2">
                            <li className="nav-item">
                                <a className="nav-link active" href="#">
                                    Buy Shares
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="buysell-title text-center mb-5 mt-5">
                        <h2 className="title">Are you want to Buy Team Share!</h2>
                    </div>
                    <div className="buysell-block">
                        <Form className="buysell-form">
                            {
                                teamDetails?.ipo_status !== 'pending' &&
                                <>
                                    <FormGroup>
                                        <Label for="buyOption">Choose how you want to buy shares</Label>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            type="radio"
                                                            name="buyOption"
                                                            value="market"
                                                            checked={buyOption === "market"}
                                                            onChange={handleBuyOptionChange}
                                                        />
                                                        Buy at Ask Price
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}><FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="buyOption"
                                                        value="limit"
                                                        checked={buyOption === "limit"}
                                                        onChange={handleBuyOptionChange}
                                                    />
                                                    Buy at Bid Price
                                                </Label>
                                            </FormGroup></Col>


                                        </Row>
                                    </FormGroup>

                                    {buyOption === "limit" && (
                                        <FormGroup className="buysell-field">
                                            <Label for="limitPrice">Enter Bid Price (Runs)</Label>
                                            <Input
                                                type="number"
                                                id="limitPrice"
                                                className="form-control form-control-lg form-control-number"
                                                value={limitPrice}
                                                onChange={handleLimitPriceChange}
                                                placeholder="Enter your Bid"
                                                invalid={!!limitPriceError} // Show validation error if exists
                                            />
                                            {limitPriceError && <FormFeedback>{limitPriceError}</FormFeedback>}

                                        </FormGroup>
                                    )}
                                </>
                            }



                            <div className="form-group buysell-field">
                                <div className="form-label-group">
                                    <Label className="form-label" for="buysell-amount">
                                        Enter Number of Shares that you want to buy
                                    </Label>
                                </div>
                                {console.log("teamDetails?.ipo_status ", teamDetails?.ipo_status)}
                                <div className="form-control-group">
                                    <Input
                                        type="number"
                                        id="runsInput"
                                        value={teamShares}
                                        className="form-control form-control-lg form-control-number"
                                        onChange={handleSharesChange}
                                        invalid={error ? true : false}
                                        placeholder="Enter number of Shares"
                                        disabled={teamDetails?.ipo_status !== 'soldout' ? false : buyOption === "limit" ? limitPrice ? false : true : false}
                                    />
                                    {error && <FormFeedback>{error}</FormFeedback>}
                                </div>
                            </div>

                            <div className="form-group buysell-field">
                                <div className="form-label-group">
                                    <Label className="form-label" for="buysell-amount">
                                        Runs
                                    </Label>
                                </div>
                                <div className="form-control-group">
                                    <Input
                                        type="text"
                                        className="form-control form-control-lg form-control-number"
                                        id="buysell-amount"
                                        name="bs-amount"
                                        placeholder={`1 Share = ${teamDetails?.ipo_status === 'soldout' ? teamDetails?.asking_runs : teamDetails?.market_price} Runs`}
                                        value={runs}
                                        readOnly
                                    />
                                </div>
                                <div className="form-note-group">
                                    <span className="buysell-min form-note-alt"></span>
                                    <span className="buysell-rate form-note-alt">1 share = {teamDetails?.ipo_status === 'soldout' ? teamDetails?.asking_runs : teamDetails?.market_price} Runs</span>
                                </div>
                            </div>

                            <div className="buysell-field form-action">
                                <Button
                                    color="primary"
                                    className="btn btn-lg btn-block btn-primary"
                                    onClick={handleContinueToBuy}
                                >
                                    Continue to Buy
                                </Button>
                            </div>

                        </Form>
                    </div>
                </div>
                <Modal
                    isOpen={modal.buyCoin}
                    toggle={() => setModal({ buyCoin: false })}
                    className="modal-dialog-centered"
                    size="md"
                >
                    <a
                        href="#close"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm" />
                    </a>
                    <ModalBody className="modal-body-lg">
                        <BlockHead className="nk-block-head-xs text-center">
                            <BlockTitle tag="h5">Confirm Shares</BlockTitle>
                            <div className="nk-block-text">
                                <div className="caption-text">
                                    You are about to get <strong>{teamShares}</strong> Shares for <strong>{runs}</strong> Runs*
                                </div>
                                <span className="sub-text-sm">Share rate: 1 Share = {teamDetails?.ipo_status === 'soldout' ? teamDetails?.asking_runs : teamDetails?.market_price} Runs</span>
                            </div>
                        </BlockHead>
                        <Block>
                            <div className="buysell-field form-action text-center">
                                <div>
                                    <Button
                                        color="primary"
                                        className="btn btn-primary btn-lg"
                                        onClick={handleConfirmOrder}
                                    >
                                        {isAddSharesLoading ? <Spinner size="sm" color="light" /> : "Buy Shares"}
                                    </Button>
                                </div>
                                <div className="pt-3">
                                    <a
                                        href="#"
                                        className="link link-danger"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                    >
                                        Cancel
                                    </a>
                                </div>
                            </div>
                        </Block>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={modal.confirm}
                    toggle={() => setModal({ confirm: false })}
                    className="modal-dialog-centered"
                    size="md"
                >
                    <a
                        href="#close"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm" />
                    </a>
                    <ModalBody className="modal-body-lg text-center">
                        <div className="nk-modal">
                            <Icon name="check" className="nk-modal-icon icon-circle icon-circle-xxl bg-success" />
                            <h4 className="nk-modal-title">Successfully buy share!</h4>
                            <div className="nk-modal-text">
                                <p className="caption-text">
                                    Successfully buy <strong>{teamShares}</strong> Shares for <strong>{runs} Runs</strong> USD.
                                </p>
                            </div>
                            <div className="nk-modal-action-lg">
                                <ul className="btn-group gx-4">
                                    <li>
                                        <Link to={teamDetails?.ipo_status === 'soldout' ? `${process.env.PUBLIC_URL}/team-auction-list` : `${process.env.PUBLIC_URL}/team-ipo-list`} className="btn btn-lg btn-mw btn-primary">
                                            Return
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
            <CustomToast
                open={toastMessage?.toastType ? true : false}
                handleClose={handleClose}
                severity={toastMessage?.toastType}
                message={toastMessage?.message}
            />
        </React.Fragment>
    );
};

export default BuyTeamShare;
