import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Spinner, UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, Badge, DropdownItem } from "reactstrap";
import {
    Button,
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    PaginationComponent,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
} from "../../../components/Component";
import useApiService from "../../../hooks/apiService";
import { useSelector } from "react-redux";
import moment from 'moment';

const SellOrdersList = () => {
    const { apiService, toastMessage, setToastMessage } = useApiService();
    const { userInfo } = useSelector((state) => state.menu)
    const [data, setData] = useState([]);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState("asc");
    const [isTeamShareHoldingLoading, setIsTeamShareHoldingsLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [sessionExpired, setSessionExpired] = useState(false);

    const fetchTeamShareHoldings = async () => {
        try {
            setIsTeamShareHoldingsLoading(true); // Show loader when fetching data

            const params = {
                page: currentPage,
                limit: itemPerPage,
                order: sort,
                filterName: onSearchText,
                member_id: userInfo?.id,
                order_status: 'sell'
            };

            console.log("params", params);

            const { result, success, message, total } = await apiService(
                'GET',
                '/fetch-member-team-share-status-holding-transaction', // Adjust the endpoint if necessary
                null,
                params
            );

            console.log("get recharge res", total, result)
            if (success) {
                setData(result);
                setTotalRows(total);
            } else if (result?.length === 0) {
                setData([]);
                handleApiError('No Wallet report found...');
            } else if (message === 'Failed to authenticate') {
                handleApiError('Failed to authenticate');
                setSessionExpired(true);
                setData([]);
            } else {
                handleApiError(message);
                setData([]);
            }
        } catch (error) {
            console.error('Failed to fetch matches:', error);
            handleApiError(error);
        } finally {
            setIsTeamShareHoldingsLoading(false); // Hide loader after data is fetched or an error occurs
        }
    };

    // Sorting data
    const sortFunc = () => {
        let defaultData = [...data];
        if (sort === "dsc") {
            defaultData.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
        } else if (sort === "asc") {
            defaultData.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
        }
        setData(defaultData);
    };

    // Changing state value when searching name
    useEffect(() => {
        fetchTeamShareHoldings(); // Fetch data when component mounts or dependencies change
    }, [currentPage, itemPerPage, sort, onSearchText]);

    // Get current list, pagination
    // const indexOfLastItem = currentPage * itemPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemPerPage;
    // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);

    const handleApiError = (message) => {
        // Handle API errors here
        alert(message);
    };

    return (
        <React.Fragment>
            <Head title="Selling Report"></Head>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Sellings Report</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {totalRows} sellings.</p>
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Card className="card-bordered card-stretch">
                        <div className="card-inner-group">
                            <div className="card-inner">
                                <div className="card-title-group">
                                    <div className="card-title">
                                        <h5 className="title">All Sellings Holdings</h5>
                                    </div>
                                    <div className="card-tools me-n1">
                                        <ul className="btn-toolbar">
                                            <li>
                                                <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                                                    <Icon name="search"></Icon>
                                                </Button>
                                            </li>
                                            <li className="btn-toolbar-sep"></li>
                                            <li>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                                        <Icon name="setting"></Icon>
                                                    </DropdownToggle>
                                                    <DropdownMenu end>
                                                        <ul className="link-check">
                                                            <li>
                                                                <span>Show</span>
                                                            </li>
                                                            <li className={itemPerPage === 10 ? "active" : ""}>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    href="#dropdownitem"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        setItemPerPage(10);
                                                                    }}
                                                                >
                                                                    10
                                                                </DropdownItem>
                                                            </li>
                                                            <li className={itemPerPage === 15 ? "active" : ""}>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    href="#dropdownitem"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        setItemPerPage(15);
                                                                    }}
                                                                >
                                                                    15
                                                                </DropdownItem>
                                                            </li>
                                                        </ul>
                                                        <ul className="link-check">
                                                            <li>
                                                                <span>Order</span>
                                                            </li>
                                                            <li className={sort === "dsc" ? "active" : ""}>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    href="#dropdownitem"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        setSortState("dsc");
                                                                        sortFunc();
                                                                    }}
                                                                >
                                                                    DESC
                                                                </DropdownItem>
                                                            </li>
                                                            <li className={sort === "asc" ? "active" : ""}>
                                                                <DropdownItem
                                                                    tag="a"
                                                                    href="#dropdownitem"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        setSortState("asc");
                                                                        sortFunc();
                                                                    }}
                                                                >
                                                                    ASC
                                                                </DropdownItem>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                                        <div className="search-content">
                                            <Button
                                                className="search-back btn-icon toggle-search"
                                                onClick={() => {
                                                    setSearchText("");
                                                    toggle();
                                                }}
                                            >
                                                <Icon name="arrow-left"></Icon>
                                            </Button>
                                            <input
                                                type="text"
                                                className="form-control border-transparent form-focus-none"
                                                placeholder="Search by Order Id"
                                                value={onSearchText}
                                                onChange={(e) => setSearchText(e.target.value)}
                                            />
                                            <Button className="search-submit btn-icon">
                                                <Icon name="search"></Icon>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DataTableBody bodyclass="nk-tb-tnx">
                                <DataTableHead>
                                    <DataTableRow>
                                        <span>Team Name</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span>Shares Buy</span>
                                    </DataTableRow>
                                    <DataTableRow size="lg">
                                        <span>Shares Value</span>
                                    </DataTableRow>
                                    <DataTableRow size="lg">
                                        <span>Market Value/Share</span>
                                    </DataTableRow>
                                    <DataTableRow size="lg" className="text-center">
                                        <span>Status</span>
                                    </DataTableRow>
                                    <DataTableRow size="sm" className="text-end">
                                        <span>Order Date</span>
                                    </DataTableRow>
                                    <DataTableRow className="nk-tb-col-tools"></DataTableRow>
                                </DataTableHead>

                                {
                                    isTeamShareHoldingLoading ? (
                                        <tr>
                                            <td colSpan="5" className="text-center"><Spinner color="primary"
                                                type="grow" /></td>
                                        </tr>
                                    ) :
                                        data.length > 0
                                            ? data.map((item) => {
                                                return (
                                                    <DataTableItem key={item.id}>
                                                        <DataTableRow>
                                                            <div className="nk-tnx-type">
                                                                <div
                                                                    className="nk-tnx-type-icon bg-danger-dim text-danger"
                                                                >
                                                                    <Icon name="arrow-up-right"></Icon>
                                                                </div>
                                                                <div className="nk-tnx-type-text">
                                                                    <span className="tb-lead">{item?.team_name}</span>
                                                                    <span className="tb-date">{moment(item.createdOn).format('MM-DD-YYYY HH:mm:ss')}</span>
                                                                </div>
                                                            </div>
                                                        </DataTableRow>
                                                        <DataTableRow >
                                                            <span className="tb-lead-sub">{item?.total_shares}</span>
                                                        </DataTableRow>
                                                        <DataTableRow >
                                                            <span className="tb-lead-sub">{Number(item?.runs_credit_debit)?.toFixed(2)}</span>
                                                        </DataTableRow>
                                                        <DataTableRow >
                                                            <span className="tb-lead-sub">{Number(item?.market_price)?.toFixed(2)}</span>
                                                        </DataTableRow>
                                                        <DataTableRow className="nk-tb-col-status">
                                                            <Badge
                                                                className="badge-sm badge-dim d-none d-md-inline-flex"
                                                                color={`outline-danger`}
                                                            >
                                                                {item?.order_status?.toUpperCase()}
                                                            </Badge>
                                                        </DataTableRow>
                                                        <DataTableRow className="text-end">
                                                            <span className="tb-amount">
                                                                {moment(item.createdOn).format('MM-DD-YYYY HH:mm:ss')} <span></span>
                                                            </span>
                                                        </DataTableRow>
                                                    </DataTableItem>
                                                );
                                            })
                                            :
                                            <tr>
                                                <td colSpan="5" className="text-center mt-5">No Record Found!</td>
                                            </tr>
                                }

                            </DataTableBody>
                            <div className="card-inner">
                                {data.length > 0 && (
                                    <PaginationComponent
                                        itemPerPage={itemPerPage}
                                        totalItems={totalRows}
                                        paginate={paginate}
                                        currentPage={currentPage}
                                    />
                                )}
                            </div>
                        </div>
                    </Card>
                </Block>
        </React.Fragment>
    );
};

export default SellOrdersList;
