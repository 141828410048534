import React, { useState, useEffect } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
    Block,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockTitle,
    Button,
    Icon,
    PreviewCard,
} from "../../components/Component";
import { Form, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useApiService from "../../hooks/apiService";
import { userDetails, login, walletDetails } from "../../redux/reducer/menu";
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../config/config";
import CustomToast from "../../components/toast";
import { useLocation } from "react-router-dom";

const GeneratePin = () => {
    const { apiService, toastMessage, setToastMessage } = useApiService();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const [loading, setLoading] = useState(false);
    const [inputPin, setInputPin] = useState(""); // state to store pin
    const [reenterPin, setReenterPin] = useState(""); // state to store reentered pin
    const [isValidatePinLoading, setIsValidatePinLoading] = useState(false);
    const [pinError, setPinError] = useState(""); // state to store pin error


    // Function to generate pin for the user
    const generatePin = async () => {
        // Check if both pins match and are not empty
        console.log("inputPin", inputPin, reenterPin, location?.state?.id, !location?.state?.id)

        if (!location?.state?.id) {
            setPinError("Not Getting Users.. Try again later");
            return;
        }


        if (!inputPin || !reenterPin) {
            setPinError("Both pin fields are required");
            return;
        }
        if (inputPin !== reenterPin) {
            setPinError("Pins do not match");
            return;
        }

        const requestPayload = {
            pin: inputPin,
            id: location?.state?.id,
        };

        try {
            setLoading(true);
            const response = await axios.post(`${baseUrl}/pin-generation`, requestPayload);
            console.log("pin generation", response)
            if (response?.data?.success) {
                setToastMessage({
                    message: "Pin Generated Successfully",
                    toastType: "success",
                });
                navigate("/validate-pin", {state: {id : location?.state?.id} })

            } else {
                setToastMessage({
                    message: response?.data?.message,
                    toastType: "error",
                });
            }
        } catch (err) {
            setToastMessage({
                message: err?.response?.data?.message || 'An error occurred',
                toastType: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setToastMessage(null);
    };

    const { register, handleSubmit, formState: { errors } } = useForm();

    return (
        <>
            <Head title="Login" />
            <Block className="nk-block-middle nk-auth-body  wide-xs">
                <div className="brand-logo pb-4 text-center">
                    <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                        <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
                        <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
                    </Link>
                </div>


                <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                    <BlockHead>
                        <BlockContent>
                            <BlockTitle tag="h4">Set Your Pin</BlockTitle>
                            <BlockDes>
                                <p>Access Runsinc using your pin.</p>
                            </BlockDes>
                        </BlockContent>
                    </BlockHead>

                    {pinError && (
                        <div className="mb-3">
                            <Alert color="danger" className="alert-icon">
                                <Icon name="alert-circle" /> {pinError}
                            </Alert>
                        </div>
                    )}

                    <Form className="is-alter" onSubmit={(e) => { e.preventDefault(); generatePin(); }}>
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="inputPin">Enter Pin</label>
                            </div>
                            <div className="form-control-wrap">
                                <input
                                    type="password"
                                    id="inputPin"
                                    value={inputPin}
                                    onChange={(e) => setInputPin(e.target.value)}
                                    placeholder="Enter your pin"
                                    className="form-control-lg form-control"
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="reenterPin">Re-Enter Pin</label>
                            </div>
                            <div className="form-control-wrap">
                                <input
                                    type="password"
                                    id="reenterPin"
                                    value={reenterPin}
                                    onChange={(e) => setReenterPin(e.target.value)}
                                    placeholder="Re-enter your pin"
                                    className="form-control-lg form-control"
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <Button size="lg" className="btn-block" type="submit" color="primary">
                                {loading ? <Spinner size="sm" color="light" /> : "Set Pin"}
                            </Button>
                        </div>
                    </Form>
                </PreviewCard>
            </Block>
            <AuthFooter />
            <CustomToast
                open={toastMessage?.toastType ? true : false}
                handleClose={handleClose}
                severity={toastMessage?.toastType}
                message={toastMessage?.message}
            />
        </>
    );
};

export default GeneratePin;
