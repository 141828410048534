import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useNavigate } from "react-router";
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    Spinner
} from "reactstrap";
import {
    Button,
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    PaginationComponent,
} from "../../../components/Component";
import useApiService from "../../../hooks/apiService";
import CustomToast from "../../../components/toast";
import { useSelector } from "react-redux";

const FavoriteTeamList = () => {
    const { apiService, toastMessage, setToastMessage } = useApiService();
    const { userInfo } = useSelector((state) => state.menu)
    const navigate = useNavigate();
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState("");
    const [isFavoriteTeamListLoading, setIsFavoriteTeamListLoading] = useState(false)
    // Sorting data
    const sortFunc = (params) => {
        let defaultData = data;
        if (params === "asc") {
            let sortedData = defaultData.sort((a, b) => a.ref.localeCompare(b.ref));
            setData([...sortedData]);
        } else if (params === "dsc") {
            let sortedData = defaultData.sort((a, b) => b.ref.localeCompare(a.ref));
            setData([...sortedData]);
        }
    };

    // Changing state value when searching name
    useEffect(() => {
        if (onSearchText !== "") {
            const filteredObject = data.filter((item) => {
                return item?.ref.toLowerCase().includes(onSearchText.toLowerCase());
            });
            setData([...filteredObject]);
        } else {
            setData([...data]);
        }
    }, [onSearchText]);

    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleClose = () => {
        setToastMessage(null);
    };


    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);

    // function to toggle details modal
    const handleApiError = (err) => {
        setToastMessage({
            message: err,
            toastType: "error"
        });
    }

    const fetchFavoriteTeamList = async () => {
        try {
            setIsFavoriteTeamListLoading(true); // Show loader when fetching data

            const params = {
                page: currentPage,
                limit: itemPerPage,
                order: sort,
                filterName: onSearchText,
                member_id: userInfo?.id
            };

            console.log("params", params);

            const { result, success, message, total } = await apiService(
                'GET',
                '/fetch-favorite-team', // Adjust the endpoint if necessary
                null,
                params
            );

            console.log("get recharge res", total, result)
            if (success) {
                setData(result);
                setTotalRows(total);
            } else if (result?.length === 0) {
                setData([]);
                handleApiError('No Wallet report found...');
            } else if (message === 'Failed to authenticate') {
                handleApiError('Failed to authenticate');
                setSessionExpired(true);
                setData([]);
            } else {
                handleApiError(message);
                setData([]);
            }
        } catch (error) {
            console.error('Failed to fetch matches:', error);
            handleApiError(error);
        } finally {
            setIsFavoriteTeamListLoading(false); // Hide loader after data is fetched or an error occurs
        }
    }

    // Changing state value when searching name
    useEffect(() => {
        fetchFavoriteTeamList(); // Fetch data when component mounts or dependencies change
    }, [currentPage, itemPerPage, sort, onSearchText]);

    return (
        <React.Fragment>
            <Head title="Trasaction List - Crypto"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>All Team List</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {totalRows} teams.</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <ul className="nk-block-tools g-3">
                                <li>
                                    <Button color="primary" className="btn-icon" style={{ padding: 10 }} onClick={() => {
                                        if (totalRows === 3) {
                                            setToastMessage({
                                                message: "Can not add more than 3 favorite teams",
                                                toastType: "error"
                                            })
                                        } else {
                                            navigate("/add-favorite-team", {
                                                state: totalRows
                                            })
                                        }
                                    }}>
                                        <Icon name="plus"></Icon>Add Favorite Team
                                    </Button>
                                </li>
                            </ul>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <DataTable className="card-stretch">
                        <div className="card-inner">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Teams List</h5>
                                </div>
                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <Button
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </Button>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                                    <Icon name="setting"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu end className="dropdown-menu-xs">
                                                    <ul className="link-check">
                                                        <li>
                                                            <span>Show</span>
                                                        </li>

                                                        <li className={itemPerPage === 10 ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setItemPerPage(10);
                                                                }}
                                                            >
                                                                10
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={itemPerPage === 15 ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setItemPerPage(15);
                                                                }}
                                                            >
                                                                15
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                    <ul className="link-check">
                                                        <li>
                                                            <span>Order</span>
                                                        </li>
                                                        <li className={sort === "dsc" ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setSortState("dsc");
                                                                    sortFunc("dsc");
                                                                }}
                                                            >
                                                                DESC
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={sort === "asc" ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setSortState("asc");
                                                                    sortFunc("asc");
                                                                }}
                                                            >
                                                                ASC
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                                    <div className="search-content">
                                        <Button
                                            onClick={() => {
                                                setSearchText("");
                                                toggle();
                                            }}
                                            className="search-back btn-icon toggle-search"
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Search by Order Id"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTableBody bodyclass="nk-tb-tnx">
                            <DataTableHead>
                                <DataTableRow>
                                    <span>Team Name</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>Add On</span>
                                </DataTableRow>

                                <DataTableRow className="nk-tb-col-tools"></DataTableRow>
                            </DataTableHead>

                            {isFavoriteTeamListLoading ? (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        <Spinner
                                            color="primary"
                                            type="grow"
                                        >
                                            Loading...
                                        </Spinner>
                                    </td>
                                </tr>
                            ) : (
                                data.length > 0
                                    ? data.map((item) => {
                                        return (
                                            <DataTableItem key={item?.id}>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead">{`${item?.team_name}`}</span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow size="sm">
                                                    <span className="tb-amount">
                                                        {item?.createdOn}
                                                    </span>
                                                </DataTableRow>
                                            </DataTableItem>
                                        );
                                    })
                                    :
                                    <tr>
                                        <td colSpan="5" className="text-center mt-5">
                                                No Record Found!
                                        </td>
                                    </tr>)}
                        </DataTableBody>
                        <div className="card-inner">
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={totalRows}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                        </div>
                    </DataTable>
                </Block>

            </Content>

            <CustomToast
                open={toastMessage?.toastType ? true : false}
                handleClose={handleClose}
                severity={toastMessage?.toastType}
                message={toastMessage?.message}
            />
        </React.Fragment>
    );
};

export default FavoriteTeamList;
